import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import StructureBlock from "../components/StructureBlock"
import useUserContext from "../hooks/useUserContext"
import { IUser } from "../interfaces/services"
// import { faker } from '@faker-js/faker'

interface ILevels {
    1: IUser[],
    2: IUser[],
    3: IUser[],
    4: IUser[],
    5: IUser[],
}

const Structure = () => {
    const user = useUserContext()
    const { t } = useTranslation()



    return (
        <>
            <h1>{t('struktura')}</h1>

            {user &&
                <StructureBlock users={[user]} level={1} />
            }
            
        </>
    )
}

export default Structure