import { Paginated } from "@feathersjs/feathers";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import '@splidejs/react-splide/css';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import client from "../../helpers/feathers";
import { IService } from "../../interfaces/services";

interface IReview extends IService {
    image?: any,
    _name: any,
    _text: any
}

const previousData: IReview[] = [

]

const Reviews = () => {
    const [reviews, setReviews] = useState<IReview[]>(previousData);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        client.service('reviews').find()
            .then((res: Paginated<IReview>) => {
                if (res.total > 0)
                    setReviews(res.data)
            })
    }, [])

    return (
        <Splide hasTrack={false}>
            <SplideTrack>
                {reviews.map(review => (
                    <SplideSlide key={review._id}>
                        <div className="about">
                            {review.image &&
                                <div className="about_img"><img src={review.image.src} alt={review._name && (review._name[i18n.language]|| '') } /></div>
                            }
                            <div className="about_text">
                                <h3>{review._name&&(review._name[i18n.language] || '')}</h3>
                                <p dangerouslySetInnerHTML={{ __html: review._text&&(review._text[i18n.language] || '')}}></p>
                            </div>
                        </div>
                    </SplideSlide>
                ))}
            </SplideTrack>
            <div className="splide__arrows">
                <button className="splide__arrow splide__arrow--prev">
                    <div className="my-arrow" />
                </button>
                <button className="splide__arrow splide__arrow--next">
                    <div className="my-arrow reverse" />
                </button>
            </div>
        </Splide>
    )
}

export default Reviews