import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from "react-i18next";
import styles from './LanguageSwitcher.module.scss';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation()

    const handleLang = (event: SelectChangeEvent) => {
        i18n.changeLanguage(event.target.value as string);
    };

    if (i18n)
        return (
            <FormControl>
                <Select
                    value={i18n.language}
                    label="Language"
                    onChange={handleLang}
                    className={styles.lng}
                >
                    {i18n.languages.map((lang) => (
                        <MenuItem value={lang} key={lang}>{lang}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    else
        return null;
}

export default LanguageSwitcher