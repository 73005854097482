import { useEffect } from "react";
import { Datagrid, DateField, downloadCSV, Edit, EditButton, Exporter, FilterList, FilterListItem, FunctionField, ImageField, List, NumberField, NumberInput, ReferenceField, ReferenceInput, ResourceProps, SelectField, SelectInput, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput, useEditContext, useListContext, useRecordContext, useShowContext } from "react-admin";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FilterIcon from '@mui/icons-material/Money'
import { Box, Card, CardContent, Toolbar } from '@mui/material'
import moment from "moment";
import { TRANSACTION_STATUSES, TRANSACTION_TYPES } from "../constants";
import PaidIcon from '@mui/icons-material/Paid';
import jsonExport from 'jsonexport/dist'

interface ShowProps { show?: boolean }

const exporter: Exporter = (records, fetchRelatedRecords) => {
    // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
    fetchRelatedRecords(records, 'user', 'users').then(users => {
        const data = records.map((record: any) => ({
            id: record._id,
            user: users[record.user].email,
            amount: record.amount,
            transactionType: TRANSACTION_TYPES.find(t => t.id === record.transactionType)?.name
        }));
        return jsonExport(data, {
            headers: ['id', 'user', 'amount', 'transactionType'],
        }, (err, csv) => {
            downloadCSV(csv, 'transactions');
        });
    });
};

const TransactionTypeFilter = () => {
    return (
        <FilterList label="Транзакции" icon={<CompareArrowsIcon />}>
            <FilterListItem label='Пополнение' value={{ transactionType: 'replenishment' }} />
            <FilterListItem label='Вывод средств' value={{ transactionType: 'withdrawal' }} />
            <FilterListItem label='Покупка' value={{ transactionType: 'purchase' }} />
            <FilterListItem label='Перевод' value={{ transactionType: 'transfer' }} />
            <FilterListItem label='Начисления' value={{ transactionType: 'reward' }} />
            <FilterListItem label='Оборот' value={{ transactionType: 'income' }} />
        </FilterList>
    )
}

const TransactionStatusFilter = () => {
    return (
        <FilterList label="Статус" icon={<PriceCheckIcon />}>
            <FilterListItem label='Ожидают' value={{ status: 'inProgress' }} />
            <FilterListItem label='Одобренны' value={{ status: 'approved' }} />
            <FilterListItem label='Отклонены' value={{ status: 'rejected' }} />
        </FilterList>
    )
}

const TransactionDateFilter = () => {
    return (
        <FilterList label=" Дата" icon={<AccessTimeIcon />}>
            <FilterListItem
                label="Сегодня"
                value={{
                    createdAt: {
                        $gte: moment().startOf('day').toISOString(),
                    },
                }}
            />
            <FilterListItem
                label="На этой неделе"
                value={{
                    createdAt: {
                        $gte: moment().startOf('week').toISOString(),
                    }
                }}
            />
            <FilterListItem
                label="На прошлой неделе"
                value={{
                    createdAt: {
                        $gte: moment().subtract(1, 'week').startOf('week').toISOString(),
                        $lte: moment().startOf('week').toISOString()
                    }
                }}
            />
            <FilterListItem
                label="В этом месяце"
                value={{
                    createdAt: {
                        $gte: moment().startOf('month').toISOString(),
                    }
                }}
            />
            <FilterListItem
                label="В прошлом месяце"
                value={{
                    createdAt: {
                        $gte: moment().subtract(1, 'month').startOf('month').toISOString(),
                        $lte: moment().startOf('month').toISOString(),
                    }
                }}
            />
            <FilterListItem
                label="Раньше"
                value={{
                    createdAt: {
                        $lte: moment().subtract(1, 'month').startOf('month').toISOString(),
                    }
                }}
            />
        </FilterList>
    )
}

const FilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        }}
    >
        <Toolbar />
        <Card>
            <CardContent>
                <TransactionTypeFilter />
                <TransactionStatusFilter />
                <TransactionDateFilter />
            </CardContent>
        </Card>
    </Box>
);

const TransactionList = () => {

    return (
        <List aside={<FilterSidebar />} exporter={exporter} >
            <TransactionDataGrid />
        </List >
    )
}

const TransactionDataGrid = () => {
    const { filterValues } = useListContext()

    return (
        <Datagrid>
            <ReferenceField link="show" source='user' label='Пользователь' reference='users'>
                <FunctionField
                    label="Имя"
                    render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                />
            </ReferenceField>
            <SelectField source="transactionType" label='Тип транзакции' choices={TRANSACTION_TYPES} />
            <NumberField source="amount" label='Сумма' />
            {(filterValues && filterValues.transactionType === 'income') &&
                [
                    <ReferenceField link="show" source='income' label='Источник' reference='incomes'>
                        <FunctionField
                            render={(record: any) => `За оборот`}
                        />
                    </ReferenceField>,
                    <ReferenceField link="show" source='income' label='Реферал' reference='incomes'>
                        <ReferenceField link="show" source='purchase' label='purchase' reference='purchases'>
                            <ReferenceField link="show" source='user' label='Пользователь' reference='users'>
                                <FunctionField
                                    label="Имя"
                                    render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                                />
                            </ReferenceField>
                        </ReferenceField>
                    </ReferenceField>
                ]
            }
            {(filterValues && filterValues.transactionType === 'reward') &&
                [
                    <ReferenceField link="show" source='origin' label='Источник' reference='transactions'>
                        <FunctionField
                            render={(record: any) => `За продажу`}
                        />
                    </ReferenceField>,
                    <ReferenceField link="show" source='referal' label='Реферал' reference='users'>
                        <FunctionField
                            label="Имя"
                            render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                        />
                    </ReferenceField>
                ]
            }
            {(filterValues && filterValues.transactionType === 'transfer') &&
                <ReferenceField link="show" source='recipient' label='Получатель' reference='users'>
                    <FunctionField
                        label="Имя"
                        render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                    />
                </ReferenceField>
            }
            <SelectField source="status" label='Статус' choices={TRANSACTION_STATUSES} />
            <DateField source='createdAt' label='Дата' showTime />
            <ShowButton />
            <EditButton />
        </Datagrid>
    )
}

const TransactionEdit = () => {
    return (
        <Edit>
            <EditForm />
        </Edit>
    )
}

const TransactionShow = () => {
    return (
        <Show>
            <ShowForm />
        </Show>
    )
}

const EditForm = () => {
    const { record, isLoading } = useEditContext();

    if (isLoading)
        return <div>Loading...</div>
    else
        switch (record.transactionType) {
            case 'replenishment':
                return <ReplenishmentForm show={false} />
            case 'withdrawal':
                return <WithdrawalForm show={false} />
            case 'purchase':
                return <PurchaseForm show={false} />
            case 'reward':
                return <RewardForm show={false} />
            case 'income':
                return <IncomeForm show={false} />
            default:
                return null
        }

}

const ShowForm = () => {
    const { record, isLoading } = useShowContext();

    if (isLoading)
        return <div>Loading...</div>
    else
        switch (record.transactionType) {
            case 'replenishment':
                return <ReplenishmentForm show={true} />
            case 'withdrawal':
                return <WithdrawalForm show={true} />
            case 'purchase':
                return <PurchaseForm show={true} />
            case 'reward':
                return <RewardForm show={true} />
            case 'income':
                return <IncomeForm show={true} />
            default:
                return null
        }

}

const СommonFields = ({ show = false }: ShowProps) => {
    if (show)
        return [
            <TextField source='id' key="id" />,
            <ReferenceField link="show" source='user' reference='users' key="user">
                <FunctionField
                    label="Имя"
                    render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                />
            </ReferenceField>,
            <SelectField source="transactionType" choices={TRANSACTION_TYPES} key="transactionType" />,
            <NumberField source='amount' key="amount" />,
            <SelectField source="status" choices={TRANSACTION_STATUSES} key="status" />,
        ]
    else
        return [
            <TextInput source='id' disabled key="id" />,
            <ReferenceInput source='user' reference='users' key="user">
                <SelectInput optionText="email" disabled />
            </ReferenceInput>,
            <SelectInput source="transactionType" disabled choices={TRANSACTION_TYPES} key="transactionType" />,
            <NumberInput source='amount' key="amount" />,
            <SelectInput source="status" choices={TRANSACTION_STATUSES} key="status" />,
        ]
}

const ReplenishmentForm = ({ show }: ShowProps) => {
    if (show)
        return (
            <SimpleShowLayout>
                {СommonFields({ show })}
                <ImageField source='attachment.src' />
            </SimpleShowLayout>
        )
    else
        return (
            <SimpleForm>
                {СommonFields({ show })}
                <ImageField source='attachment.src' />
            </SimpleForm>
        )
}

const WithdrawalForm = ({ show }: ShowProps) => {
    if (show)
        return (
            <SimpleShowLayout>
                {СommonFields({ show })}
                <TextField source='wallet' />
                <TextField source='email' />
                <TextField source='telegram' />
            </SimpleShowLayout>
        )
    else
        return (
            <SimpleForm>
                {СommonFields({ show })}
                <TextField source='paymentSystem' />
                <TextField source='wallet' />
                <TextField source='email' />
                <TextField source='telegram' />

            </SimpleForm>
        )
}

const PurchaseForm = ({ show }: ShowProps) => {
    if (show)
        return (
            <SimpleShowLayout>
                {СommonFields({ show })}
                <ReferenceField link="show" source='purchase' reference='purchases'>
                    <TextField source="tradingAccount" />
                </ReferenceField>
                <ReferenceField link="show" source='bundle' reference='bundles'>
                    <TextField source="title" />
                </ReferenceField>
            </SimpleShowLayout>
        )
    else
        return (
            <SimpleForm>
                {СommonFields({ show })}
                <ReferenceInput source='purchase' reference='purchases'>
                    <SelectInput optionText="tradingAccount" />
                </ReferenceInput>
                <ReferenceInput source='bundle' reference='bundles'>
                    <SelectInput optionText="title" />
                </ReferenceInput>
            </SimpleForm>
        )
}

const RewardForm = ({ show }: ShowProps) => {
    if (show)
        return (
            <SimpleShowLayout>
                {СommonFields({ show })}
                <ReferenceField link="show" source='origin' reference='transactions'>
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField link="show" source='referal' reference='users'>
                    <TextField source="email" />
                </ReferenceField>
                <NumberField source="count" />
                <NumberField source="level" />
            </SimpleShowLayout>
        )
    else
        return (
            <SimpleForm>
                {СommonFields({ show })}
                <ReferenceInput source='origin' reference='transactions'>
                    <SelectInput optionText="id" />
                </ReferenceInput>
                <ReferenceInput source='referal' reference='users'>
                    <SelectInput optionText="email" />
                </ReferenceInput>
                <NumberInput source="count" />
                <NumberInput source="level" />
            </SimpleForm>
        )
}

const IncomeForm = ({ show }: ShowProps) => {
    if (show)
        return (
            <SimpleShowLayout>
                {СommonFields({ show })}
                <ReferenceField link="show" source='income' reference='incomes' label='Источник' >
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField link="show" source='referal' label='Реферал' reference='users' key="user">
                    <FunctionField
                        label="Имя"
                        render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                    />
                </ReferenceField>
                <NumberField source="level" />
            </SimpleShowLayout>
        )
    else
        return (
            <SimpleForm>
                {СommonFields({ show })}
                <ReferenceInput source='income' reference='incomes'>
                    <SelectInput optionText="id" />
                </ReferenceInput>
                <ReferenceInput source='referal' reference='users'>
                    <SelectInput optionText="email" />
                </ReferenceInput>
                <NumberInput source="level" />
            </SimpleForm>
        )
}

export const TransactionsProps: ResourceProps = {
    list: TransactionList,
    edit: TransactionEdit,
    show: TransactionShow,
    name: 'transactions',
    icon: PaidIcon,
    options: {
        label: 'Транзакции'
    }
}