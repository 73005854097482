import { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import client from "../../helpers/feathers"
import getName from "../../helpers/getName"
import useLanguageContext from "../../hooks/useLanguageContext"
import useUserContext from "../../hooks/useUserContext"
import { ITransaction, ITransactionTransfer } from "../../interfaces/services"

interface TransactionRowProps {
    transaction: ITransaction
}

const TransactionRow = ({ transaction }: TransactionRowProps) => {
    const { t } = useTranslation()
    const { locale } = useLanguageContext()
    const transactionType = useTransactionType(transaction)
    // const [text, setText] = useState('')
    const text = useText(transaction)

    // useEffect(() => {
    //     getText(transaction)
    //         .then((t) => setText(t))
    // }, [transaction])

    return (
        <>
            <Col xs={12} lg={2}>
                <div className="lc_table_date">
                    <Moment date={transaction.createdAt} locale={locale} calendar />
                </div>
            </Col>
            <Col xs={12} lg={7}>
                <div className="history_table_label" >
                    {text}
                </div>
            </Col>
            <Col xs={12} lg={3}>
                <div className={`history_table_status history_table_status_${transactionType.color}`}>
                    {`${transactionType.symbol} ${transaction.amount.toLocaleString()} $`}
                </div>
            </Col>
        </>
    )
}

const useTransactionType = (transaction: ITransaction) => {
    const user = useUserContext()
    let color = 'yellow'
    let symbol = ''
    switch (transaction.transactionType) {
        case 'withdrawal':
        case 'purchase':
            color = 'red'
            symbol = '-'
            break
        case 'replenishment':
            switch (transaction.status) {
                case 'approved':
                    color = 'green'
                    symbol = '+'
                    break
                case 'rejected':
                    color = 'red'
                    symbol = '+'
                    break
                default:
                    symbol = '+'
                    break
            }
            break
        case 'transfer':
            if (transaction.user === user?._id) {
                color = 'red'
                symbol = '-'
            }
            else {
                color = 'green'
                symbol = '+'
            }
            break
        case 'reward':
        case 'income':
            color = 'green'
            symbol = '+'
            break
        default:
            symbol = ''
            break
    }
    if (transaction.status === 'inProgress')
        color = 'yellow'
    if (transaction.status === 'rejected')
        color = 'red'

    return { color, symbol }
}

const useText = (transaction: ITransaction) => {
    const { t } = useTranslation()
    const user = useUserContext()
    const [text, setText] = useState('')

    useEffect(() => {
        const getText = async () => {

            switch (transaction.transactionType) {
                case 'withdrawal':
                    setText(t('vyvod-sredstv-s-vashego-akkaunta'))
                    break
                case 'replenishment':
                    setText(t('popolnenie-balansa'))
                    break
                case 'purchase':
                    setText(t('vy-osushestvili-pokupku'))
                    break
                case 'transfer':
                    // const transfer = transaction as ITransactionTransfer
                    if (!user)
                        break
                    if (user._id === transaction.recipient) {
                        const sender = await client.service('users').get(transaction.user)
                        setText(t('poluchenie-perevoda-sredstv-ot-drugogo-uchastnika-sender-firstname-and-and-sender-lastname-sender-firstname-sender-lastname-sender-email'))
                    }
                    else {
                        const recipient = await client.service('users').get(transaction.recipient)
                        setText(t('perevod-sredstv-drugomu-uchastniku-recipient-firstname-and-and-recipient-lastname-recipient-firstname-recipient-lastname-recipient-email'))
                    }
                    break
                case 'reward':
                case 'income':
                    const referal = await client.service('users').get(transaction.referal)
                    setText(t('poluchenie-sredstv-po-partnerskoi-prgramme-ot-getname-referal-transaction-level-uroven'))
                    break
                case 'rank':
                    setText(t('voznagrazhdenie-za-poluchenie-zvaniya-transaction-rank'))
                    break
                default:
                    break
            }
        }
        getText()
    }, [transaction, user])

    return text
}

export default TransactionRow