import { useState } from "react"
import { useTranslation } from "react-i18next"
import useUserContext from "../../hooks/useUserContext"


const MyReferal = () => {
    const { t } = useTranslation()
    const user = useUserContext()
    const [copied, setCopied] = useState(false)
    const origin = window.location.origin

    const copyHandler = () => {
        setCopied(true)
        navigator.clipboard.writeText(`${origin}/?code=${user && user.promo}`)
        setTimeout(() => setCopied(false), 1000)
    }

    return (
        <div className="col-12">
            <h2>{t('referalnaya-ssylka')}</h2>
            <div className="lc_ramka">
                <div className="lc_par">
                    <div className="lc_par_label">{t('promokod')}</div>
                    <div className="lc_par_value">{user && user.promo}</div>
                </div>
                <div className="lc_par">
                    <div className="lc_par_label">{t('ssylka')}</div>
                    <div className="lc_par_value">{origin}/?code={user && user.promo}</div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-12">

                        <button
                            className="btn-green lc_body_btn_1"
                            onClick={copyHandler}
                        >
                            {t('skopirovat-promokod')}
                            {copied &&
                                <div className="lc_body_btn_copied">{t('skopirovano')}</div>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyReferal