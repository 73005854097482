import { Resource, ResourceElement } from "react-admin";
import { AboutsProps } from "../admin/abouts";
import { AdvantagesProps } from "../admin/advantages";
import { AudiencesProps } from "../admin/audiences";
import { BundlesProps } from "../admin/bundles";
import { FaqsProps } from "../admin/faqs";
import { ImpactsProps } from "../admin/impacts";
import { IncomesProps } from "../admin/incomes";
import { MessageProps } from "../admin/messages";
import { PAProps } from "../admin/partnerAdvantages";
import { PurchaseProps } from "../admin/purchases";
import { RequestsProps } from "../admin/requests";
import { ReviewsProps } from "../admin/reviews";
import { StagesProps } from "../admin/stages";
import { SupportProps } from "../admin/supports";
import { TransactionsProps } from "../admin/transactions";
import { UserProps } from "../admin/users";

const resources: ResourceElement[] = [
    <Resource {...UserProps} />,
    <Resource {...AdvantagesProps} />,
    <Resource {...AboutsProps} />,
    <Resource {...AudiencesProps} />,
    <Resource {...BundlesProps} />,
    <Resource {...FaqsProps} />,
    <Resource {...ImpactsProps} />,
    <Resource {...PAProps} />,
    <Resource {...ReviewsProps} />,
    <Resource {...StagesProps} />,
    <Resource {...SupportProps} />,
    <Resource {...MessageProps} />,
    <Resource {...TransactionsProps} />,
    <Resource {...PurchaseProps} />,
    <Resource {...RequestsProps} />,
    <Resource {...IncomesProps} />,
]

const useResources = (permissions: any): ResourceElement[] => {
    if (permissions.includes('admin'))
        return resources
    else
        return []
}

export default useResources