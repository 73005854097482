import { Create, Datagrid, Edit, EditButton, ImageField, ImageInput, List, NumberField, NumberInput, ResourceProps, SimpleForm, TextField, TextInput, TransformData, TranslatableInputs } from 'react-admin'
import { RichTextInput } from 'ra-input-rich-text';
import { API } from '../constants';
import { uploadTransform } from '../helpers/admin';
import { useTranslation } from 'react-i18next';

const RESOURCE: string = 'stages'

const StageList = () => {
    return (
        <List
            resource={RESOURCE}
            sort={{ field: 'order', order: 'ASC' }}
        >
            <Datagrid>
                <TextField source="title" />
                <NumberField source="order" />
                <EditButton />
            </Datagrid>
        </List>
    )
}

const StageCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <TextInput source="title" fullWidth />
                <RichTextInput source="text" fullWidth />
                <NumberInput source="order" />
            </SimpleForm>
        </Create>
    )
}

const StageEdit = () => {
    const { i18n } = useTranslation()
    return (
        <Edit>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <TranslatableInputs locales={i18n.languages as string[]} >
                    <TextInput source="_title" fullWidth />
                    <RichTextInput source="_text" fullWidth />
                </TranslatableInputs>
                <TextInput source="title" fullWidth />
                <RichTextInput source="text" fullWidth />
                <NumberInput source="order" />
            </SimpleForm>
        </Edit>
    )
}

export const StagesProps: ResourceProps = {
    name: RESOURCE,
    list: StageList,
    edit: StageEdit,
    create: StageCreate,
    options: {
        label: 'Шаги',
        sub: 'home'
    }
}