import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import MyBalance from "../components/MyBalance"
import MyReferal from "../components/MyReferal"
import MyResult from "../components/MyResult"
import { createUsers } from "../helpers/fake"
import client from "../helpers/feathers"
import useNotificationContext from "../hooks/useNotificationContext"
import useUserContext from "../hooks/useUserContext"
import { IUser } from "../interfaces/services"

const Account = () => {
    const user = useUserContext()
    const { t, i18n } = useTranslation()
    useEffect(() => {
        client.reAuthenticate().then(() =>
            client.service('users').find().then((r: any) => console.log(r))
        )
    }, [])

    return (
        <>
            <div className="row pb-5">
                <MyResult />
                <MyBalance />
            </div>
            {user && user.status &&
                <div className="row pb-5">
                    <MyReferal />
                </div>
            }
            <div className="row pb-5">
                <h2>{t('operacii')}</h2>
                <div className="col-lg-4 col-12">
                    <Link to="/account/replenishment" className="btn-green lc_body_btn">
                        {t('popolnit-balans')}
                    </Link>
                </div>
                <div className="col-lg-4 col-12">
                    <Link to="/account/withdrawal" className="btn-green lc_body_btn">
                        {t('vyvod-sredstv')}
                    </Link>
                </div>
            </div>
            <Transfer />

        </>
    )
}

const Transfer = () => {
    const { t } = useTranslation()
    const user = useUserContext()
    const { addNotification } = useNotificationContext()
    const [amount, setAmount] = useState('')
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')

    const send = () => {

        client.service('transactions').create({
            transactionType: 'transfer',
            user: user?._id,
            recipient: email,
            amount: Number(amount)
        })
            .then(() => {
                
                setEmail('')
                setAmount('')
                addNotification({
                    id: new Date().getTime().toString(36),
                    text: t('perevod-uspeshno-vypolnen')
                }, 30000)
            })
            .catch((e: any) => setError(e.message))
    }

    return (
        <div className="row">
            <h2>{t('perevod-sredstv-drugomu-uchastniku')}</h2>
            {/* <p>комиссия <span>3 %</span></p> */}
            {error &&
                <p>
                    {error === 'no-user' && t('polzovatel-ne-naiden')}
                    {error === 'no-balance' && t('nedostatochno-sredstv')}
                </p>
            }
            <div className="col-lg col-12"> <input type="number" placeholder={t('summa')} value={amount} onChange={e => Number(e.target.value) > 0 && setAmount(e.target.value) } /></div>
            <div className="col-lg col-12"> <input type="text" placeholder={t('e-mail-polzovatelya')} value={email} onChange={e => setEmail(e.target.value)} /></div>
            <div className="col-lg-4 col-12">
                <button className="btn-green lc_body_btn" onClick={send}>{t('otpravit')}</button>
            </div>
        </div>
    )
}


export default Account