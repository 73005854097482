import { Outlet } from "react-router-dom"
import Footer from "./Footer"
import Header from "./Header"
import '../styles/App.scss'

const PageWrapper = () => {
    return (
        <div id='main'>
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
}
export default PageWrapper