import { Create, Datagrid, Edit, EditButton, ImageField, ImageInput, List, ResourceProps, SimpleForm, TextField, TextInput, TransformData, TranslatableInputs } from 'react-admin'
import { RichTextInput } from 'ra-input-rich-text';
import { API } from '../constants';
import { uploadTransform } from '../helpers/admin';
import { useTranslation } from 'react-i18next';

const RESOURCE: string = 'faqs'

const FaqList = () => {
    return (
        <List
            resource={RESOURCE}
        >
            <Datagrid>
                <TextField source="question" />
                <EditButton />
            </Datagrid>
        </List>
    )
}

const FaqCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <TextInput source="question" fullWidth />
                <RichTextInput source="answer" fullWidth />
            </SimpleForm>
        </Create>
    )
}

const FaqEdit = () => {
    const { i18n } = useTranslation()

    return (
        <Edit>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <TranslatableInputs locales={i18n.languages as string[]} >
                    <TextInput source="_question" fullWidth />
                    <RichTextInput source="_answer" fullWidth />
                </TranslatableInputs>
                <TextInput source="question" fullWidth />
                <RichTextInput source="answer" fullWidth />
            </SimpleForm>
        </Edit>
    )
}

export const FaqsProps: ResourceProps = {
    name: RESOURCE,
    list: FaqList,
    edit: FaqEdit,
    create: FaqCreate,
    options: {
        label: 'FAQ',
        sub: 'home'
    }
}