
import { Grid, List, ListItem, ListItemText } from "@mui/material"
import { useEffect } from "react"
import { usePermissions } from "react-admin"
import { useNavigate } from "react-router-dom"
import Statistic from "./Statistic"
import Users from "./Users"

const Dashboard = () => {
    const { permissions } = usePermissions()
    const navigate = useNavigate()

    useEffect(() => {
        if (permissions && !permissions.includes('admin'))
            navigate('/')
    }, [permissions])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Users />
            </Grid>
            <Grid item xs={12} md={4}>
                <Statistic />
            </Grid>
        </Grid>
    )
}

export default Dashboard
