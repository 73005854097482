import { Paginated } from "@feathersjs/feathers"
import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import client from "../../helpers/feathers"
import { IService } from "../../interfaces/services"

interface IAbout extends IService {
    text?: string,
    _text:any,
    image?: any
}

const previousData: IAbout[] = [
]

const About = () => {
    const { t, i18n } = useTranslation();
    const [abouts, setAbouts] = useState<IAbout[]>(previousData)

    useEffect(() => {
        client.service('abouts').find()
            .then((res: Paginated<IAbout>) => {
                if (res.total > 0)
                    setAbouts(res.data)
            })
    }, [])
    
    return (
        <Row className="bot">
            {abouts.map(about => (
                <Col xs={12} lg={4} key={about._id}>
                    <div className="ram">
                        <img src={about.image.src} alt="" />
                        <div dangerouslySetInnerHTML={{ __html: about._text[i18n.language] || '' }}></div>
                    </div>
                </Col>
            ))}
        </Row>
    )
}

export default About