import { Paginated, Query } from "@feathersjs/feathers"
import { ChangeEventHandler, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { Col, Modal } from "react-bootstrap"
import client from "../helpers/feathers"
import useUserContext from "../hooks/useUserContext"
import { ISupport } from "../interfaces/services"
import Moment from 'react-moment';
import { useNavigate } from "react-router-dom"
import Pagination from "../components/Pagination"
import useLanguageContext from "../hooks/useLanguageContext"
import { useTranslation } from "react-i18next"

interface SupportFilterProps {
    status: string,
    setStatus: Dispatch<SetStateAction<string>>
}

interface SupportRowProps {
    support: ISupport
}

interface NewSupportProps {
    close: Function
}

interface Itab {
    label: string,
    value: string
}

const supportssService = client.service('supports')

const Support = () => {
    const { t } = useTranslation()
    const user = useUserContext()
    const [supports, setSupports] = useState<Paginated<ISupport>>()
    const [show, setShow] = useState(false)
    const [status, setStatus] = useState('')

    const handleOpen = () => setShow(true)
    const handleClose = () => setShow(false)

    useEffect(() => {
        if (user) {
            load()
            supportssService.on('created', update)
        }
        return () => supportssService.removeListener('create', update)
    }, [user])

    useEffect(() => {
        load()
    }, [status])

    const load = (skip: number = 0) => {
        const query: Query = {
            user: user?._id,
            $sort: { createdAt: -1 },
            $skip: skip
        }

        if (status)
            query.status = status

        supportssService.find({
            query
        })
            .then((supports: Paginated<ISupport>) => setSupports(supports))
    }

    const update = useCallback((d: any) => {
        load()
    }, [])

    return (
        <>
            <h1>{t('tekhpodderzhka')}</h1>
            <div className="support_header row mt-5 justify-content-between">
                <div className="col-12 col-lg-auto">
                    <div className="support_filter">
                        {t('filtrovat')}
                        <img src="/images/icon-settings.png" alt="" />
                    </div>
                </div>
                <div className="col-12 col-lg-auto">
                    <button onClick={handleOpen} className="support_create">
                        <img src="/images/icon-plus.png" alt="" />
                        {t('sozdat-zapros')}
                    </button>
                </div>
            </div>
            <SupportFilter
                status={status}
                setStatus={setStatus}
            />
            <div className="lc_table support_table">
                <div className="row lc_table_header">
                    <div className="col-12 col-lg-8">{t('tema')}</div>
                    <div className="col-12 col-lg-2">{t('data-sozdaniya')}</div>
                    <div className="col-12 col-lg-2">{t('status')}</div>
                </div>
                <div className="row lc_table_body support_table_body">
                    {supports &&
                        supports.data.map((support) => (
                            <SupportRow support={support} key={support._id} />
                        ))
                    }
                </div>
            </div>
            {/* пагинация */}
            {supports &&
                <Pagination state={supports} load={load} />
            }

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <NewSupport close={handleClose} />
                </Modal.Body>
            </Modal>
        </>
    )
}

const SupportFilter = ({ status, setStatus }: SupportFilterProps) => {
    const { t } = useTranslation()

    const tabs: Itab[] = [
        {
            label: t('vse'),
            value: ''
        },
        {
            label: t('otkryta'),
            value: 'open'
        },
        {
            label: t('v-obrabotke'),
            value: 'inProgress'
        },
        {
            label: t('vypolnena-i-zakryta'),
            value: 'approved'
        },
        {
            label: t('ne-vypolnena-i-zakryta'),
            value: 'rejected'
        },
    ]

    return (
        <div className="support_tabs">
            {tabs.map(tab => (
                <div
                    className={`support_tabs_item ${tab.value === status ? 'active' : ''}`}
                    onClick={() => setStatus(tab.value)}
                >
                    {tab.label}
                </div>
            ))}
        </div>
    )
}

const SupportRow = ({ support }: SupportRowProps) => {
    const [last, setLast] = useState<any>()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { locale } = useLanguageContext()

    useEffect(() => {
        client.service('messages').find({ query: { support: support._id, $limit: 1, $populate: 'user' } })
            .then((messages: any) => messages.total > 0 && setLast(messages.data[0]))
    }, [support])

    const getStatus = (status: string) => {
        switch (status) {
            case 'open':
                return t('otkryt')
            case 'inProgress':
                return t('v-obrabotke-0')
            case 'approved':
            case 'rejected':
                return t('zakryt')
            default:
                return status
        }
    }
    const getColor = (status: string) => {
        switch (status) {
            case 'open':
            case 'inProgress':
                return 'support_table_status_yelow'
            case 'approved':
                return 'support_table_status_green'
            case 'rejected':
                return 'support_table_status_red'
            default:
                return status
        }
    }

    const handleClick = () => {
        navigate(support._id)
    }

    return (
        <>
            <Col xs={12} lg={8} onClick={handleClick}>
                <div className="support_table_theme_wrapper">
                    <div className="support_table_theme">
                        <div>
                            {support.theme}
                        </div>
                        {last &&
                            <div>{t('posl-soobshenie')} <a href="#">{last.user.firstName}</a> | <Moment locale={locale} date={last.createdAt} calendar /></div>
                        }
                    </div>
                </div>
            </Col>
            <Col xs={12} lg={2} onClick={handleClick}>
                <div className="lc_table_date support_table_date">
                    <div>
                        <Moment locale={locale} date={support.createdAt} format={'DD.MM.YYYY'} /><br />
                        {t('v')}{' '}<Moment locale={locale} date={support.createdAt} format={'HH:mm'} />
                    </div>
                </div>
            </Col>
            <Col xs={12} lg={2} onClick={handleClick}>
                <div className={`support_table_status ${getColor(support.status)}`}>
                    {getStatus(support.status)}
                </div>
            </Col>
        </>
    )
}

const NewSupport = ({ close }: NewSupportProps) => {
    const { t } = useTranslation()
    const user = useUserContext()
    const [theme, setTheme] = useState('')
    const [text, setText] = useState('')

    const changeHandler = (dispatch: Dispatch<SetStateAction<string>>): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => {
        return (event) => {
            dispatch(event.target.value)
        }
    }

    const send = () => {
        if (user && theme && text)
            client.service('supports').create({
                user: user._id,
                theme,
                text
            }).then(() => {
                setTheme('')
                setText('')
                close()
            })
    }

    return (
        <div className="lc_body">
            <input type="text" className="mb-4" placeholder={t('tema-0')} value={theme} onChange={changeHandler(setTheme)} />
            <textarea className="mb-4" placeholder={t('soobsheniya')} value={text} onChange={changeHandler(setText)} />
            <div className="btn-green text-center" onClick={send}>{t('sozdat')}</div>
        </div>
    )
}



export default Support