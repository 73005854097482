import { RichTextInput } from "ra-input-rich-text"
import { List, Datagrid, TextField, EditButton, Create, SimpleForm, TextInput, ImageInput, ImageField, Edit, ResourceProps, TranslatableInputs } from "react-admin"
import { useTranslation } from "react-i18next"
import { uploadTransform } from "../helpers/admin"

const RESOURCE: string = 'partner-advantages'

const PAList = () => {
    return (
        <List
            resource={RESOURCE}
        >
            <Datagrid>
                <TextField source="text" />
                <EditButton />
            </Datagrid>
        </List>
    )
}

const PACreate = () => {
    return (
        <Create transform={uploadTransform('image')}>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <RichTextInput source="text" fullWidth />
                <ImageInput source='image'>
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}

const PAEdit = () => {
    const { i18n } = useTranslation()
    return (
        <Edit transform={uploadTransform('image')}>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <TranslatableInputs locales={i18n.languages as string[]} >
                    <RichTextInput source="_text" fullWidth />
                </TranslatableInputs>
                <RichTextInput source="text" fullWidth />
                <ImageInput source='image'>
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
}

export const PAProps: ResourceProps = {
    name: RESOURCE,
    list: PAList,
    edit: PAEdit,
    create: PACreate,
    options: {
        label: 'Партнерская программа',
        sub: 'home'
    }
}