import styles from './Logo.module.scss'

interface LogoProps {
  link?: boolean,
  size?: number
}

const Logo = ({ link = true, size = 71 }: LogoProps) => {
  return (
    <>
      {link ?
        <a href="/" className={styles.logo} style={{ fontSize: `${size}px` }}><span>L</span>ok<span>i</span></a>
        :
        <div className={styles.logo} style={{ fontSize: `${size}px` }} ><span>L</span>ok<span>i</span></div>
      }
    </>
  )
}

export default Logo