import { Paginated } from "@feathersjs/feathers"
import { Card, CardContent, CardHeader, List, ListItem, ListItemText, Typography } from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { API } from "../../constants"
import client from "../../helpers/feathers"
import { ITransaction, IUser } from "../../interfaces/services"

const purchasesService = client.service('purchases')

const Statistic = () => {
    const [totalPurchases, setTotalPurchases] = useState(0)
    const [profit, setProfit] = useState(0)
    const [rewards, setRewards] = useState(0)
    const [incomes, setIncomes] = useState(0)
    const [ranks, setRanks] = useState(0)
    const [balance, setBalance] = useState(0)

    const getTransaktionsAmount = (transactionType: string, dispatch: Dispatch<SetStateAction<number>>) => {
        const request = (skip: number) => {
            client
                .service('transactions')
                .find({
                    query: {
                        transactionType,
                        $skip: skip
                    }
                })
                .then((res: Paginated<ITransaction>) => {
                    dispatch((prev) => prev + res.data.reduce((acc, row) => acc + row.amount, 0))
                    if (res.total > res.skip + res.data.length)
                        request(res.skip + res.limit)
                })
        }
        request(0)
    }

    const getAllBallance = (dispatch: Dispatch<SetStateAction<number>>) => {
        const request = (skip: number) => {
            const query = { $skip: skip }
            client
                .service('users')
                .find({ query })
                .then((res: Paginated<IUser>) => {
                    dispatch((prev) => prev + res.data.reduce((acc, row) => acc + (row.balance || 0), 0))
                    if (res.total > res.skip + res.data.length)
                        request(res.skip + res.limit)
                })
        }
        request(0)
    }

    useEffect(() => {
        purchasesService
            .find()
            .then((res: Paginated<{}>) => setTotalPurchases(res.total))
        getTransaktionsAmount('purchase', setProfit)
        getTransaktionsAmount('reward', setRewards)
        getTransaktionsAmount('income', setIncomes)
        getTransaktionsAmount('rank', setRanks)
        getAllBallance(setBalance)
    }, [])

    return (
        <Card>
            <CardHeader title="Финансы" />
            <CardContent>
                <List>
                    <Item label="Проданно ботов" value={totalPurchases} />
                    <Item label="Прибыль" value={profit} />
                    <Item label="Общий баланс" value={balance.toFixed(2)} />
                    <ListItem>
                        <ListItemText primary={<Typography variant="h6">Выплаты</Typography>} />
                    </ListItem>
                    <Item label="Всего" value={(rewards + incomes + ranks).toFixed(2)} />
                    <Item label="За продажи" value={rewards.toFixed(2)} />
                    <Item label="За оборот" value={incomes.toFixed(2)} />
                    <Item label="За статус" value={ranks.toFixed(2)} />
                </List>
            </CardContent>
        </Card>
    )
}

const Item = ({ label, value }: { label: string, value: number | string }) => {
    return (
        <ListItem>
            <ListItemText primary={label} />
            <ListItemText primary={value} sx={{ textAlign: 'end' }} />
        </ListItem>
    )
}

export default Statistic