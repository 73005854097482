import { useTranslation } from "react-i18next"

const Promotions = () => {
    const { t } = useTranslation()

    return (
        <>
            <h1>{t('akcii')}</h1>
            {/* <div className="promotion">
                <div className="promotion_body">
                    <h2 className="promotion_title">
                        Длинный пример названия темы акции Заполненно для примера в два
                        предложения.
                    </h2>
                    <p className="promotion_text">
                        This is Photoshop's version of Lorem Ipsum. Proin gravida nibh vel velit
                        auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi
                        elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit
                        amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum
                        velit. Nam nec tellus a odio tincidunt auctor a ornare odio....
                    </p>
                </div>
                <div className="promotion_footer">
                    <div className="promotion_date">25 января 2022 в 10:45:49</div>
                </div>
            </div> */}
        </>
    )
}

export default Promotions