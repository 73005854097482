import { Col } from "react-bootstrap"
import Moment from "react-moment"
import useLanguageContext from "../../hooks/useLanguageContext"
import { IPurchase } from "../../interfaces/services"

interface PurchaseRowProps {
    purchase: IPurchase
}

const PurchaseRow = ({ purchase }: PurchaseRowProps) => {
    const { locale } = useLanguageContext()

    return (
        <>
            <Col xs={12} lg={7}>
                <div className="history_table_label" >
                    {purchase.tradingAccount}
                </div>
            </Col>
            <Col xs={12} lg={3}>
                <div className={`history_table_label`}>
                    {purchase.income}
                </div>
            </Col>
            <Col xs={12} lg={2}>
                <div className="lc_table_date">
                    <Moment date={purchase.createdAt} locale={locale} calendar />
                </div>
            </Col>
        </>
    )
}

export default PurchaseRow