import { Paginated } from "@feathersjs/feathers"
import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import client from "../../helpers/feathers"
import { IService } from "../../interfaces/services"

interface IStage extends IService {
    _title: any,
    _text: any,
    order: number
}

const previousData: IStage[] = [
    
]

const Stages = () => {
    const [stages, setStages] = useState<IStage[]>(previousData)
    const { t, i18n } = useTranslation();

    useEffect(() => {
        client.service('stages').find()
            .then((res: Paginated<IStage>) => {
                if (res.total > 0)
                    setStages(res.data as IStage[]);
            })
    }, [])

    return (
        <>
            {stages.map(stage => (
                <Row className="stages" key={stage._id}>
                    <Col lg={2}>
                        <div className="stages_nuber">0{stage.order}</div>
                    </Col>
                    <Col>
                        <h3>{stage._title && (stage._title[i18n.language]|| '')}</h3>
                        <p dangerouslySetInnerHTML={{ __html: stage._text && (stage._text[i18n.language]|| '')}}></p>
                    </Col>
                </Row>
            ))}
        </>
    )
}

export default Stages