import { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Sales from "../components/Sales"
import Turnover from "../components/Turnover"
import structureContext from "../contexts/structureContext"
import useStructure from "../hooks/useStructure"
import useStructureContext from "../hooks/useStructureContext"
import useUserContext from "../hooks/useUserContext"

interface Irank {
    label: string,
    condition: JSX.Element,
    reward: number,
    target: number,
}

const Provider = structureContext.Provider

const Affiliate = () => {
    const { t } = useTranslation()
    const structure = useStructure()
    return (
        <Provider value={structure}>
            <h1>{t('partnyorskaya-programma')}</h1>

            <div className="lc_tabs">
                <Tabs defaultActiveKey="sales" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="sales" title={t('prodazha-loki')}>
                        <Sales />
                    </Tab>
                    <Tab eventKey="turnover" title={t('torgovyi-oborot')}>
                        <Turnover />
                    </Tab>
                    <Tab eventKey="career" title={t('karera')}>
                        <Career />
                    </Tab>
                </Tabs>
            </div>
        </Provider>
    )
}

const useRanks = () => {
    const { t } = useTranslation()

    const ranks: Irank[] = [
        {
            label: t('stazher'),
            condition: <>{t('neobkhodimo-prodat')} <span>10</span> {t('botov')}</>,
            reward: 150,
            target: 10
        },
        {
            label: t('menedzher'),
            condition: <>{t('neobkhodimo-prodat-0')} <span>50</span> {t('botov-0')}</>,
            reward: 500,
            target: 50
        },
        {
            label: t('rukovoditel'),
            condition: <> {t('neobkhodimo-prodat-1')} <span>100</span> {t('botov-1')}{' '}
                {t('imet-1-referala-urovnya')} <span>{t('menedzher-0')}</span></>,
            reward: 1500,
            target: 100
        },
        {
            label: t('direktor-1-urovnya'),
            condition: <>{t('neobkhodimo-prodat-2')} <span>200</span> {t('botov-2')}{' '}
                {t('imet')} <span>1</span> {t('referala-urovnya')} <span>{t('rukovoditel-0')}</span>{' '}
                {t('imet-0')} <span>2</span> {t('referalov-urovnya')} <span>{t('menedzher-1')}</span></>,
            reward: 5000,
            target: 200
        },
        {
            label: t('direktor-2-urovnya'),
            condition: <>{t('neobkhodimo-prodat-3')} <span>500</span> {t('botov-3')}{' '}
                {t('imet-1')} <span>1</span> {t('referala-urovnya-0')} <span>{t('direktor')}</span>{' '}
                {t('imet-2')} <span>2</span> {t('referalov-urovnya-0')} <span>{t('rukovoditel-1')}</span></>,
            reward: 15000,
            target: 500
        }
    ]
    return ranks
}


const Career = () => {
    const { t } = useTranslation()
    const ranks = useRanks()
    return (
        <>
            <p>{t('prisvoenie-novogo-statusa-proiskhodit-1-go-chisla-kazhdogo-mesyaca')}</p>
            {ranks.map((r) => (
                <Rank rank={r} key={r.label} />
            ))}
        </>
    )
}



const Rank = ({ rank }: { rank: Irank }) => {
    const { t } = useTranslation()
    const user = useUserContext()
    const { loaded, getTransactions, } = useStructureContext()
    const [total, setTotal] = useState(0)
    const ranks = useRanks()

    const chekRank = (rank: string, target: string) => {
        const _r = ranks.findIndex(item => item.label === rank);
        const _t = ranks.findIndex(item => item.label === target);
        if (_r >= _t)
            return true
        else
            return false
    }

    useEffect(() => {
        if (loaded && user)
            getTransactions()
                .then((transactions) => {
                    const bots: number = transactions.data.reduce((sum, transaction) => sum + (transaction.count || 0), 0)
                    let _percent: number = 0
                    if (bots > rank.target)
                        _percent = 100
                    else
                        _percent = bots / rank.target * 100
                    if (_percent > 1 && !chekRank(user.rank || '', rank.label) && !chekRank(user.rank || '', t('rukovoditel-2')))
                    // _percent = _percent - 1
                    _percent = _percent
                    setTotal(_percent)
                })
    }, [loaded])

    return (
        <div className="row g-4 rank">
            <div className="col-12 col-lg-9">
                <div className="rank-info">
                    <div>
                        <div className="rank_title">{rank.label}</div>
                        <div className="rank_condition">
                            {rank.condition}
                        </div>
                    </div>
                    <div className="rank_reward">{rank.reward} $</div>
                </div>
            </div>
            <div className="col-12 col-lg-3">
                <div className="rank_btn ">
                    {/* <img src="/images/check.png" alt="" /> */}
                    {total < 100 ?
                        t('v-processe')
                        :
                        t('polucheno')
                    }
                </div>
            </div>
            <div className="col-12 col-lg-9">
                <div className="rank_progress">
                    <div style={{ width: `${total}%` }} />
                </div>
            </div>
            <div className="col-12 col-lg-3 rank_percent">
                {total.toFixed(2)}%
            </div>
        </div>
    )
}

export default Affiliate