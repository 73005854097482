import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

import translationRU from './locales/ru/translation.json';
import translationEN from './locales/en/translation.json';
import translationCN from './locales/cn/translation.json';
import translationKZ from './locales/kz/translation.json';
import translationUA from './locales/ua/translation.json';

// the translations
const resources = {
  ru: {
    translation: translationRU
  },
  kz: {
    translation: translationKZ
  },
  cn: {
    translation: translationCN
  },
  en: {
    translation: translationEN
  },
  ua: {
    translation: translationUA
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: ['ru', 'kz', 'cn', 'en', 'ua'],
    keySeparator: false, // we do not use keys in form messages.welcome
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag'],
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });


export default i18n;