import { useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../../hooks/useLogin';
import InputLabel from '@mui/material/InputLabel';
import LoginForm from '../LoginForm';
import Logo from '../Logo'
import RegisterForm from '../RegisterForm';
import styles from './Header.module.scss'
import LanguageSwitcher from '../LanguageSwitcher';

const Header = () => {
    const { t, i18n } = useTranslation()
    const login = useLogin()
    const navigate = useNavigate()
    const [loginShow, setLoginShow] = useState(false);
    const [registerShow, setRegisterShow] = useState(false);

    const handleLoginClose = () => setLoginShow(false);
    const handleLoginShow = () => setLoginShow(true);
    const handleRegisterClose = () => setRegisterShow(false);
    const handleRegisterShow = () => setRegisterShow(true);


    return (
        <header>
            <Container>
                <Row className='align-items-center py-4 justify-content-center justify-content-md-between justify-content-lg-start'>
                    <Col xs='auto' className='mb-2 mb-lg-0' >
                        <Logo />
                    </Col>
                    <div className="col ps-5 d-none d-lg-block">
                        <nav className={styles.nav}>
                            <a href='#s-about'>{t('o-bote-0')}</a>
                            <a href='#s-price'>{t('stoimost')}</a>
                            <a href='#s-affiliate'>{t('partnerka')}</a>
                            <a href='#s-stages'>{t('podklyuchenie-0')}</a>
                            <a href='#s-faq'>FAQ</a>
                            <a href='#s-contacts'>{t('kontakty')}</a>
                        </nav>
                    </div>
                    <div className="col-auto">
                        <div className={styles.reg}>
                            {login ?
                                <>
                                    <button onClick={() => navigate('/account')}>
                                        <img src="/images/log.png" alt="" />
                                        {t('lichnyi-kabinet')}
                                    </button>
                                </>

                                :
                                <>
                                    <button onClick={handleLoginShow}>
                                        <img src="/images/log.png" alt="" />
                                        {t('voiti')}
                                    </button>
                                    <button onClick={handleRegisterShow}>
                                        <img src="/images/reg.png" alt="" />
                                        {t('registraciya')}
                                    </button>
                                    {/* <button className={styles.lng}>RU</button> */}
                                </>
                            }
                            <LanguageSwitcher/>
                        </div>
                    </div>
                </Row>
            </Container>
            <Modal show={loginShow} onHide={handleLoginClose} centered>
                <Modal.Body>
                    <LoginForm />
                </Modal.Body>
            </Modal>
            <Modal show={registerShow} onHide={handleRegisterClose} centered>
                <Modal.Body>
                    <RegisterForm />
                </Modal.Body>
            </Modal>
        </header>

    )
}
export default Header