import { Paginated } from "@feathersjs/feathers"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import client from "../../helpers/feathers"
import useUserContext from "../../hooks/useUserContext"
import { IPurchase } from "../../interfaces/services"
import Pagination from "../Pagination"
import PurchaseRow from "../PurchaseRow"

const MyPurchases = () => {
    const { t } = useTranslation()
    const [purchases, setPurchases] = useState<Paginated<IPurchase>>()

    const user = useUserContext()

    useEffect(() => {
        if (user)
            load()
    }, [user])

    const load = (skip: number = 0) => {
        client
            .service('purchases')
            .find({
                query: {
                    user: user?._id,
                    $sort: { createdAt: -1 },
                    $skip: skip
                }
            })
            .then((r: Paginated<IPurchase>) => setPurchases(r))
    }

    return (
        <div className="mt-5">
            <h1>{t('moi-boty')}</h1>

            <div className="lc_table">
                <div className="row lc_table_header ">
                    <div className="col-lg-7">{t('nomer-scheta')}</div>
                    <div className="col-lg-3">{t('oborot')}</div>
                    <div className="col-lg-2">{t('data-pokupki')}</div>
                </div>
                <div className="row lc_table_body">
                    {purchases?.data.map(purchase =>(
                        <PurchaseRow purchase={purchase} key={purchase._id} />
                    ))}
                </div>
            </div>
            {purchases &&
                <Pagination state={purchases} load={load} />
            }
        </div>
    )
}

export default MyPurchases