import { ChangeEventHandler, Dispatch, SetStateAction, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { API } from "../../constants"
import useNotificationContext from "../../hooks/useNotificationContext"
import styles from './ResetForm.module.scss'


const ResetForm = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { addNotification } = useNotificationContext()
    const [email, setEmail] = useState('')
    const [error, setError] = useState<string | JSX.Element>('')

    const changeHandler = (dispatch: Dispatch<SetStateAction<string>>): ChangeEventHandler<HTMLInputElement> => {
        return (event) => {
            setError('')
            dispatch(event.target.value)
        }
    }

    const send = () => {
        fetch(`${API}/reset?email=${email}`)
            .then((r) => {
                if (r.status === 200) {
                    addNotification({
                        id: new Date().getTime().toString(36),
                        text: t('otpravlenno')
                    })
                    navigate('/login')
                }
                if (r.status === 404) {
                    addNotification({
                        id: new Date().getTime().toString(36),
                        text: t('polzovatel-ne-naiden')
                    })
                    setError('error')
                }
            })
    }

    return (
        <Row>
            <Col xs={12}>
                <input
                    type="email"
                    className={`${styles.input} ${error ? styles.error : ''}`}
                    placeholder={t('email')}
                    value={email}
                    onChange={changeHandler(setEmail)}
                />
            </Col>
            <Col xs={12}>
                <button
                    className="btn-green"
                    style={{ width: '100%' }}
                    onClick={send}
                >
                    {t('otpravit')}
                </button>
            </Col>
        </Row>
    )
}

export default ResetForm