import { Paginated, Query } from "@feathersjs/feathers"
import { ChangeEventHandler, KeyboardEventHandler, useEffect, useState } from "react"
import { Col, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import client from "../../helpers/feathers"
import useUserContext from "../../hooks/useUserContext"
import { ITransactionPurchase, IService } from "../../interfaces/services"

interface BundlesProps {
    col?: number
}

interface SingleBundleProps extends BundlesProps {
    bundle: IBundle,
    haveLoki: boolean
}

interface TradingAccountProps {
    value: string,
    index: number,
    onChange: Function,
    handleKeyPress: KeyboardEventHandler<HTMLInputElement>,
    bundle: IBundle,
}

interface IBundle extends IService {
    count: number,
    title: string
    price: number,
    sale?: number
}

const previousData: IBundle[] = [

]

const Bundles = ({ col = 3 }: BundlesProps) => {
    const [bundles, setBundles] = useState<IBundle[]>([])
    const [haveLoki, setHaveLoki] = useState(false)
    const user = useUserContext()

    // const haveLoki:boolean = purchases?.find(item=>item.)

    useEffect(() => {
        client.service('bundles').find({ query: { $limit: 25 } })
            .then((res: Paginated<IBundle>) => {
                if (res.total > 0)
                    setBundles(res.data)
            })
        if (user)
            load()
    }, [])

    const load = (skip: number = 0) => {
        const query: Query = {
            user: user?._id,
            transactionType: 'purchase',
            bundle: { $in: ["628bb829f1b95d2df199eb56", "628bb844f1b95d2df199eb5b", "628bb87cf1b95d2df199eb65", "628bb87cf1b95d2df199eb65", "632966429a349855389c1635", "6329665e9a349855389c163a", "632966849a349855389c163f"] }
        }
        client
            .service('transactions')
            .find({ query })
            .then((r: Paginated<ITransactionPurchase>) => setHaveLoki(r.total > 0))
    }

    return (
        <Row>
            {bundles.map(item => (
                <SingleBundle bundle={item} col={col} key={item._id} haveLoki={haveLoki} />
            ))}
        </Row>
    )
}

const SingleBundle = ({ bundle, col, haveLoki }: SingleBundleProps) => {
    const { t } = useTranslation()
    const user = useUserContext()
    const navigate = useNavigate()


    const [tradingAccount, setTradingAccount] = useState<string[]>([])
    const [error, setError] = useState('')
    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        const arr: string[] = []
        for (let index = 0; index < bundle.count; index++) {
            arr.push('')
        }
        setTradingAccount(arr)
    }, [bundle])

    const handleModalOpen = () => user ? setModalShow(true) : navigate('/register')
    const handleModalClose = () => setModalShow(false)
    const handlerChange = (value: string, key: number) => {
        setError('')
        const newArray = tradingAccount.slice()
        newArray[key] = value
        setTradingAccount(newArray)
    }
    // const handleInput: ChangeEventHandler<HTMLInputElement> = (event) => (!event.target.value || reg.test(event.target.value)) && setTradingAccount(event.target.value)
    const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => event.key === 'enter' && send()

    const send = () => {
        console.log(user);

        const price: number = bundle.sale || bundle.price

        if (tradingAccount.find((item) => item === '') !== undefined) {
            setError(t('zapolnite-vse-polya'))
            return
        }

        if (bundle._id !== "66e7e518c91b1dae224fffbe" && ((user && user.balance && user.balance < price) || !user?.balance)) {
            setError(t('nedostatochno-sredstv'))
            return
        }

        client.service('transactions').create({
            transactionType: 'purchase',
            user: user?._id,
            bundle: bundle._id,
            amount: price,
            purchases: tradingAccount
        }).then(() => {
            navigate('/account/history')
        })
    }

    return (
        <>
            <Col xs={12} lg={col} >
                <div className="pack">
                    <div className="pack_size">x{bundle.count}</div>
                    <p>{bundle.title}</p>
                    {bundle.sale ?
                        <div className="pack_prices">{bundle.sale} $ <del>{bundle.price} $</del> </div>
                        :
                        <div className="pack_prices">{bundle.price} $</div>
                    }
                    <button
                        onClick={handleModalOpen}
                        className="btn-green"
                    // disabled={bundle.title.match("Hedz") ? !haveLoki : false}
                    >
                        {t('kupit')}
                    </button>
                </div>
            </Col>
            <Modal show={modalShow} onHide={handleModalClose} centered>
                <Modal.Body>
                    <div className="lc_body">
                        <Row>
                            <Col xs={12}>
                                {error &&
                                    <p>{error}</p>
                                }
                            </Col>
                            {tradingAccount.map((item, index) => (
                                <Col xs={12} key={index} className="mt-2">
                                    <TradingAccount value={item} index={index} bundle={bundle} onChange={handlerChange} handleKeyPress={handleKeyPress} />
                                </Col>
                            ))}
                            {/* <Col xs={12}>
                                <input autoFocus type="text" placeholder="номер торгового счета" value={tradingAccount} onChange={handleInput} onKeyPress={handleKeyPress} />
                            </Col> */}
                            <Col xs={12} className="mt-2">
                                <button onClick={send} className="btn-green" style={{ width: '100%' }}>{t('kupit-0')}</button>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const TradingAccount = ({ value, index, onChange, handleKeyPress, bundle }: TradingAccountProps) => {
    const { t } = useTranslation()
    let placeholder: string = t('nomer-torgovogo-scheta')
    const reg = /^\d+$/
    const handleInput: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (!event.target.value || reg.test(event.target.value))
            onChange(event.target.value, index)
    }

    if (bundle._id === '632966429a349855389c1635' || bundle._id === '6329665e9a349855389c163a' || bundle._id === '632966849a349855389c163f') {
        if (index < bundle.count / 2)
            placeholder = t('nomer-torgovogo-scheta-1')
        else
            placeholder = t('nomer-torgovogo-scheta-2')
    }

    if (bundle._id === '64b7349d65240b51f8bdd147') {
        switch (index) {
            case 0:
                placeholder = t('nomer-torgovogo-scheta-1')
                break;
            case 1:
                placeholder = t('nomer-torgovogo-scheta-2')
                break;
            case 2:
                placeholder = t('nomer-torgovogo-scheta-3')
                break;
            default:
                break;
        }
    }

    return (
        <input autoFocus type="text" placeholder={placeholder} value={value} onChange={handleInput} onKeyPress={handleKeyPress} />
    )
}

export default Bundles