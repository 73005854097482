import { Create, Datagrid, Edit, EditButton, ImageField, ImageInput, List, ResourceProps, SimpleForm, TextField, TextInput, TransformData, TranslatableInputs } from 'react-admin'
import { RichTextInput } from 'ra-input-rich-text';
import { API } from '../constants';
import { uploadTransform } from '../helpers/admin';
import { useTranslation } from 'react-i18next';

const RESOURCE: string = 'advantages'

const AdvantagesList = () => {
    return (
        <List
            resource={RESOURCE}

        >
            <Datagrid>
                <TextField source="title" />
                <EditButton />
            </Datagrid>
        </List>
    )
}

const AdvantagesCreate = () => {
    const { i18n } = useTranslation()

    return (
        <Create transform={uploadTransform('image')}>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <TranslatableInputs locales={i18n.languages as string[]} >
                    <TextInput source="_title" fullWidth />
                    <RichTextInput source="_text" fullWidth />
                </TranslatableInputs>

                <ImageInput source='image'>
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}

const AdvantagesEdit = () => {
    const { i18n } = useTranslation()

    return (
        <Edit transform={uploadTransform('image')}>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <TranslatableInputs locales={i18n.languages as string[]} >
                    <TextInput source="_title" fullWidth />
                    <RichTextInput source="_text" fullWidth />
                </TranslatableInputs>
                {/* del */}
                <TextInput source="title" fullWidth />
                <RichTextInput source="text" fullWidth />
                {/*  */}
                <ImageInput source='image'>
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
}

export const AdvantagesProps: ResourceProps = {
    name: RESOURCE,
    list: AdvantagesList,
    edit: AdvantagesEdit,
    create: AdvantagesCreate,
    options: {
        label: 'Преимущества',
        sub: 'home'
    }
}