import { Paginated } from "@feathersjs/feathers"
import { useEffect, useState } from "react"
import client from "../../helpers/feathers"
import useUserContext from "../../hooks/useUserContext"
import useStructure from '../../hooks/useStructure'
import { ITransactionIncome, ITransactionReward, IUser } from "../../interfaces/services"
import { useTranslation } from "react-i18next"

const MyResult = () => {
    const { t } = useTranslation()
    const user = useUserContext()
    const [referals, setReferals] = useState<Paginated<IUser>>()
    const [sum, setSum] = useState<number>(0)
    const { loaded, getSum, getTransactions, getIncomes } = useStructure()

    useEffect(() => {
        if (user)
            client.service('users').find({ query: { referrer: user._id } })
                .then((res: Paginated<IUser>) => setReferals(res))
    }, [user])

    useEffect(() => {
        getTransactions()
            .then((transactions: Paginated<ITransactionReward>) => setSum((prev) => prev + getSum(transactions)))
        getIncomes()
            .then((transactions: Paginated<ITransactionIncome>) => setSum((prev) => prev + getSum(transactions)))
    }, [loaded])

    return (
        <div className="col">
            <h2>{t('moi-rezultaty')}</h2>
            <div className="lc_ramka">
                <div className="lc_par">
                    <div className="lc_par_label">{t('priglashennye-lichno')}</div>
                    <div className="lc_par_value">{referals && referals.total}</div>
                </div>
                <div className="lc_par">
                    <div className="lc_par_label">{t('referalnye-bonusy')}</div>
                    <div className="lc_par_value">{sum.toLocaleString()} $</div>
                </div>
            </div>
        </div>
    )
}

export default MyResult