import { CloneButton, Datagrid, DateField, DateInput, DateTimeInput, Edit, EditButton, FunctionField, List, NumberField, Pagination, ReferenceField, ReferenceInput, ReferenceManyField, ResourceProps, SelectInput, Show, ShowButton, SimpleForm, SimpleShowLayout, Tab, TabbedShowLayout, TextField, TextInput, useRecordContext } from "react-admin";
import ShopTwoIcon from '@mui/icons-material/ShopTwo';


const CreateIncome = () => {
    const record = useRecordContext()

    return (
        <CloneButton
            resource="incomes"
            label="Создать"
            record={{ purchase: record.id }}
        />
    )
};

const PurchaseList = () => {
    return (
        <List>
            <Datagrid>
                <ReferenceField link="show" source='user' label='Пользователь' reference='users'>
                    <FunctionField
                        label="Имя"
                        render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                    />
                </ReferenceField>
                {/* <ReferenceField link="show" source='bundle' label='Бот' reference='bundles'>
                    <TextField source='title' />
                </ReferenceField> */}
                <TextField source='tradingAccount' label='Торговый счет' />
                <NumberField source='income' label='Оборот' />
                <DateField source='createdAt' label='Дата' showTime />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List >
    )
}

const PurchaseEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <TextInput source='id' disabled />
                <ReferenceInput source='user' label='Пользователь' reference='users'>
                    <SelectInput optionText="email" />
                </ReferenceInput>
                <ReferenceInput source='transaction' label='Транзакция' reference='transactions'>
                    <SelectInput optionText="id" />
                </ReferenceInput>
                <TextInput source='tradingAccount' label='Торговый счет' />
                <DateTimeInput source='createdAt' label='Дата' />
            </SimpleForm>
        </Edit>
    )
}

const PurchaseShow = () => {
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField source='id' />
                    <ReferenceField link="show" source='user' label='Пользователь' reference='users'>
                        <FunctionField
                            label="Имя"
                            render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                        />
                    </ReferenceField>
                    <ReferenceField link="show" source='transaction' label='Транзакция' reference='transactions'>
                        <TextField source='id' />
                    </ReferenceField>
                    <NumberField source='income' label='Оборот' />
                    <TextField source='tradingAccount' label='Торговый счет' />
                    <DateField source='createdAt' label='Дата' showTime />
                </Tab>
                <Tab label="incomes">
                    <ReferenceManyField label="Оборот" reference="incomes" target="purchase" pagination={<Pagination />}>
                        <Datagrid>
                            <DateField source='date' />
                            <NumberField source='amount' />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceManyField>
                    <CreateIncome />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export const PurchaseProps: ResourceProps = {
    list: PurchaseList,
    edit: PurchaseEdit,
    show: PurchaseShow,
    icon: ShopTwoIcon,
    name: 'purchases',
    options: {
        label: 'Продажи'
    }
}