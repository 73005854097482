import { API } from "../constants";

export const uploadFile = async (file: any) => {
    
    const formData = new FormData();
    formData.append('file', file)

    const response = await fetch(`${API}/uploads`, {
        method: 'POST',
        body: formData
    })
    const img = await response.json()

    return img;
}