import { ChangeEventHandler, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import client from '../../helpers/feathers'
import useNotificationContext from '../../hooks/useNotificationContext'
import usePromoContext from '../../hooks/usePromoContext'
import styles from './RegisterForm.module.scss'

const RegisterForm = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const _promo = usePromoContext()
    const { addNotification } = useNotificationContext()
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')
    const [promo, setPromo] = useState(_promo)
    const [error, setError] = useState('')

    // useEffect(() => {
    //     setPromo(_promo)
    // },[_promo])

    useEffect(() => {
        if (error !== '')
            addNotification({
                id: new Date().getTime().toString(36),
                text: error
            })
    }, [error])

    const changeHandler = (dispatch: Dispatch<SetStateAction<string>>): ChangeEventHandler<HTMLInputElement> => {
        return (event) => {
            setError('')
            dispatch(event.target.value)
        }
    }

    const send = () => {
        client.service('users').create({
            email,
            password,
            firstName,
            lastName,
            referrer: promo,
        }).then(() => {
            // client.authenticate({
            //     strategy: 'local',
            //     email,
            //     password
            // }).then(() => navigate('/account'))
            navigate('/login')
        }).catch((e: any) => {
            switch (e.message) {
                case 'Promo error':
                    setError(t('nevernyi-promokod'))
                    break;
                case 'email: value already exists.':
                    setError(t('email-uzhe-zaregestrirovan'))
                    break;
                case 'bad password':
                    setError(t('parol-dolzhen-soderzhat-ot-8-do-16-simvolov-vklyuchaya-kak-minimum-odnu-strochnuyu-bukvu-odnu-zaglavnuyu-bukvu-i-odnu-cifru'))
                    break;
                default:
                    setError(e.message)
                    break;
            }
        })
    }

    return (
        <Row>
            {/* {error &&
                <Col xs={12}>
                    <p style={{ textAlign: 'center' }}>{error}</p>
                </Col>
            } */}
            <Col xs={12}>
                <input
                    type="text"
                    className={[styles.input, error === 'Неверный промокод' ? styles.error : ''].join(' ')}
                    placeholder={t('promokod')}
                    value={promo}
                    onChange={changeHandler(setPromo)}
                />
            </Col>
            <Col xs={12}>
                <input
                    type="email"
                    className={[styles.input, error === 'Email уже зарегестрирован' ? styles.error : ''].join(' ')}
                    placeholder={t('email')}
                    value={email}
                    onChange={changeHandler(setEmail)}
                />
            </Col>
            <Col xs={12}>
                <input
                    type="text"
                    className={styles.input}
                    placeholder={t('imya')}
                    value={firstName}
                    onChange={changeHandler(setFirstName)}
                />
            </Col>
            <Col xs={12}>
                <input
                    type="text"
                    className={styles.input}
                    placeholder={t('familiya')}
                    value={lastName}
                    onChange={changeHandler(setLastName)}
                />
            </Col>
            <Col xs={12}>
                <input
                    type="password"
                    className={[styles.input, error === 'Пароль должен содержать от 8 до 16 символов, включая как минимум одну строчную букву, одну заглавную букву и одну цифру' ? styles.error : ''].join(' ')}
                    placeholder={t('parol')}
                    value={password}
                    onChange={changeHandler(setPassword)} />
            </Col>
            <Col xs={12}>
                <button className="btn-green" style={{ width: '100%' }} onClick={send}>{t('zaregistrirovatsya')}</button>
            </Col>
            <Col xs={12}>
                <p>{t('est-akkaunt')} <Link to={'/login'}>{t('voiti')}</Link></p>
            </Col>
        </Row>
    )
}

export default RegisterForm