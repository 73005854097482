import { createContext } from "react";
import { ILanguage } from "../interfaces/language";

export const defaultLanguage : ILanguage = {
    label: 'Russian',
    locale: 'ru',
}

const languageContext = createContext<ILanguage>(defaultLanguage)

export default languageContext