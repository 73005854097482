import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import client from "../../helpers/feathers";
import useUserContext from "../../hooks/useUserContext";

const MyBalance = () => {
    const { t } = useTranslation()
    const user = useUserContext();
    const [referrer, setReferrer] = useState<any | undefined>();

    useEffect(() => {
        if (user && user.referrer)
            client.service('users').get(user.referrer)
                .then((u: any) => setReferrer(u));
    }, [user]);

    return (
        <div className="col">
            <h2>{t('moi-balans')}</h2>
            <div className="lc_ramka">
                <div className="lc_par">
                    <div className="lc_par d-block">{t('uchetnaya-zapis')}{' '}
                        {user &&
                            <>
                                {user.status ?
                                    <span>{t('aktivirovana')}</span>
                                    :
                                    <span className='red'>{t('ne-aktivirovana')}</span>}
                            </>}
                    </div>
                    {/* <div class="lc_par_value">0</div> */}
                </div>
                <div className="lc_par">
                    <div className="lc_par_label">{t('moi-sponsor')}</div>
                    <div className="lc_par_value">
                        {referrer ?
                            `${referrer.firstName} ${referrer.lastName}`
                            :
                            t('loki-bot')}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default MyBalance