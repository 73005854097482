import { ChangeEventHandler, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import client from "../helpers/feathers"
import useNotificationContext from "../hooks/useNotificationContext"
import useUserContext from "../hooks/useUserContext"

const Withdrawal = () => {
    const { t } = useTranslation()
    const {addNotification} = useNotificationContext()
    const [paymentSystem, setPaymentSystem] = useState(t('usdt-trc-20-0'))
    const [amount, setAmount] = useState<number>()
    const [wallet, setWallet] = useState('')
    const [email, setEmail] = useState('')
    const [telegram, setTelegram] = useState('')

    const user = useUserContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (user)
            setEmail(user.email)
    }, [user])

    const amountHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
        const _amount = Number(event.target.value)
        if (user && user.balance && _amount)
            setAmount(_amount <= user.balance ? _amount : user.balance)
    }

    const send = () => {
        if (!amount) {
            addNotification({
                id: Math.random().toString(),
                text:t('pozhaluista-ukazhite-summu-vyvoda')
            }, 2000)
            return
        }
        if (wallet === '') {
            addNotification({
                id: Math.random().toString(),
                text:t('pozhaluista-ukazhite-nomer-koshelka')
            }, 2000)
            return
        }
        client.service('transactions').create({
            transactionType: 'withdrawal',
            user: user?._id,
            amount,

            paymentSystem,
            wallet,
            email,
            telegram
        })
            .then(() => {
                navigate('/account/history')
            })
    }

    return (
        <>
            <h1>{t('forma-vyvoda-sredstv')}</h1>
            <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="lc_settings_field">
                        <div className="withdrawal">{t('vybirete-sposob-vyvoda')}</div>
                        <div className="lc_settings_input select">
                            <select>
                                <option>{t('usdt-trc-20-0')}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="lc_settings_field">
                        <div className="withdrawal">{t('summa-vyvoda-v-usd')}</div>
                        <input
                            type="text"
                            className="lc_settings_input"
                            placeholder={t('vvedite-summu')}
                            value={amount || ''}
                            onChange={amountHandler}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="lc_settings_field">
                        <div className="withdrawal">{t('nomer-koshelka')}</div>
                        <input
                            type="text"
                            className="lc_settings_input"
                            placeholder={t('ukazhite-vash-adres-usdt-trc20')}
                            value={wallet}
                            onChange={e => setWallet(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row text-center">
                <h2>{t('dannye-dlya-obratnoi-svyazi')}</h2>
                <div className="col-lg-6 col-12">
                    <div className="lc_settings_field">
                        <div className="withdrawal">E-mail</div>
                        <input
                            type="text"
                            className="lc_settings_input"
                            placeholder="email@example.ru"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="lc_settings_field">
                        <div className="withdrawal">
                            {t('telegram')} <span>{t('neobyazatelno')}</span>
                        </div>
                        <input
                            type="text"
                            className="lc_settings_input"
                            placeholder={t('imya-polzovatelya')}
                            value={telegram}
                            onChange={e => setTelegram(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-5 text-center">
                <div className="col-12">
                    <div className="withdrawal">
                        <p>
                            {t('razrabotchiki-loki-ne-berut-komissiyu-s-vas-budet-uderzhana-komissiya-seti-tron-orientirovochno-1')}
                        </p>
                        <button onClick={send} className="withdrawal_btn">{t('vyvesti')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Withdrawal