import { useTranslation } from "react-i18next"

const Education = () => {
    const { t, i18n } = useTranslation()

    return (
        <>
            <h1>{t('obuchenie')}</h1>
            <div className="row g-4">
                <div className="col-12 col-lg-6 col-lg-4">
                    <div className="education_card">
                        <div className="education_title">
                            <img
                                src="/images/icon-pdf.png"
                                alt=""
                                className="education_icon"
                            />
                            {t('dlya-partnerov')}
                        </div>
                        <a className="education_link" target="_blank" href={t('educations-dlya-partnerov-loki-pdf')}>
                            {t('skachat')}
                        </a>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-lg-4">
                    <div className="education_card">
                        <div className="education_title">
                            <img
                                src="/images/icon-pdf.png"
                                alt=""
                                className="education_icon"
                            />
                            {t('dlya-investora')}
                        </div>
                        <a className="education_link" target="_blank" href={i18n.language === 'ua'?'/educations/LokiUa.pdf':'/educations/Loki and AUDi.pdf'}>
                            {t('skachat-0')}
                        </a>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-lg-4">
                    <div className="education_card">
                        <div className="education_title">
                            <img
                                src="/images/icon-pdf.png"
                                alt=""
                                className="education_icon"
                            />
                            {t('zapusk-sovetnika')}
                        </div>
                        <a className="education_link" target="_blank" href={'/educations/запуск советника.pdf'}>
                            {t('skachat-1')}
                        </a>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-lg-4">
                    <div className="education_card">
                        <div className="education_title">
                            <img
                                width="28"
                                src="/images/icon-youtube.png"
                                alt=""
                                className="education_icon"
                            />
                            {t('kak-torguet-loki')}
                        </div>
                        <a className="education_link" target="_blank" href={'/educations/Как торгует Loki.mp4'}>
                            {t('skachat-2')}
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Education