import { Application } from "@feathersjs/feathers";
import { AuthenticationRequest } from "@feathersjs/authentication"
import { AuthProvider, UserIdentity } from "react-admin";

interface AuthOptions {
    strategy?: string,
    permissionsField?: string,
    passwordField?: string,
    usernameField?: string,
}

const getAuthProvider = (client: Application, options: AuthOptions): AuthProvider => {

    const strategy = options.strategy || 'local',
        passwordField = options.passwordField || 'password',
        usernameField = options.usernameField || 'email',
        permissionsKey = 'permissions'

    return {
        // authentication
        login: ({ username, password }) => {
            const data: AuthenticationRequest = {
                strategy: options.strategy || 'local',
            }
            data[passwordField] = password
            data[usernameField] = username

            return client.authenticate(data)
        },
        logout: () => {
            return client.logout().then(() => localStorage.removeItem(permissionsKey))
        },
        checkError: (error) => {
            const status = error.status;
            if (status === 401 || status === 403) {
                client.logout().then(() => Promise.reject())
            }
            return Promise.resolve();
        },
        checkAuth: () => {
            return client
                .reAuthenticate()
                .then(() => Promise.resolve())
                .catch(() => Promise.reject({ redirectTo: '/admin/login' }));
        },
        getIdentity: () => {
            return client.get('authentication').then((res: any) => {
                const identity: UserIdentity = {
                    id: res.user._id,
                }
                if (res.user.firstName || res.user.lastName)
                    identity.fullName = `${res.user.firstName || ''} ${res.user.lastName || ''}`
                else
                    identity.fullName = res.user.email
                if (res.user.avatar)
                    identity.avatar = res.user.avatar
                return identity
            })
        },
        // authorization
        getPermissions: () => {
            console.log('getPermissions');
            const localStoragePermissions = localStorage.getItem(permissionsKey)
            // If any, provide them.
            if (localStoragePermissions) {
                return Promise.resolve(JSON.parse(localStoragePermissions))
            }
            try {
                return client.get('authentication').then((res: any) => {
                    const jwtPermissions = res.user.roles
                    localStorage.setItem(permissionsKey, JSON.stringify(jwtPermissions));
                    return Promise.resolve(jwtPermissions);
                })
            }
            catch (err) {
                return Promise.reject()
            }
        },
        getRoles: () => Promise.resolve(/* ... */),
    }
}

export default getAuthProvider