import { Paginated } from "@feathersjs/feathers";
import { ChangeEventHandler, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import client from "../helpers/feathers";
import { IMessage, ISupport, IUser } from "../interfaces/services";
import Moment from 'react-moment';
import { Modal } from "react-bootstrap";
import useUserContext from "../hooks/useUserContext";
import { useTranslation } from "react-i18next";

interface NewMessageProps {
    support: ISupport,
    close: Function
}

const Chat = () => {
    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate()
    const [support, setSupport] = useState<ISupport>()
    const [messages, setMessages] = useState<Paginated<IMessage>>()
    const [show, setShow] = useState(false)

    const handleOpen = () => setShow(true)
    const handleClose = () => setShow(false)

    useEffect(() => {
        if (id)
            client.service('supports').get(id)
                .then((support: ISupport) => {
                    setSupport(support)
                    client.service('messages').find({ query: { support: support._id, $populate: 'user', $limit: 100 } })
                        .then((res: Paginated<IMessage>) => setMessages(res))
                })
    }, [id])

    if (support)
        return (
            <>
                <div className="dialog_header">
                    <button onClick={() => navigate(-1)}>
                        <div className="my-arrow" />
                        {t('nazad')}
                    </button>
                    <div className="dialog_header_date">
                        {t('sozdano')} <Moment date={support.createdAt} format={t('dd-mmmm-yyyy-v-hh-mm-ss')} />
                    </div>
                    <div className="dialog_header_status">
                        {support.status}
                    </div>
                </div>
                <div className="dialog_title">
                    {support.theme}
                </div>
                <div className="dialog_body">
                    <p>
                        {support.text}
                    </p>
                </div>
                <button className="btn-trans dialog_btn" onClick={handleOpen}>{t('napisat-kommentarii')}</button>
                {messages && messages.data.map(message => (
                    <div className="dialog_comment" key={message._id}>
                        <div className="dialog_comment_autor">{(message.user as IUser).firstName}
                            {(message.user as IUser).roles?.includes('admin') &&
                                <span className="dialog_comment_autor_rank">{t('moderator-loki')}</span>
                            }
                        </div>
                        <div className="dialog_comment_body">
                            <p dangerouslySetInnerHTML={{ __html: message.text || '' }} />
                        </div>
                        <div className="dialog_comment_footer">
                            <div className="dialog_comment_date"><Moment date={message.createdAt} format={t('dd-mmmm-yyyy-v-hh-mm-ss-0')} /></div>
                            {/* <div className="dialog_comment_btn">Цитировать</div> */}
                            <div className="dialog_comment_btn" onClick={handleOpen}>{t('otvetit')}</div>
                        </div>
                    </div>
                ))}
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Body>
                        <NewMessage support={support} close={handleClose} />
                    </Modal.Body>
                </Modal>
            </>

        )
    else
        return null
}

const NewMessage = ({ support, close }: NewMessageProps) => {
    const { t } = useTranslation()
    const user = useUserContext()
    const [text, setText] = useState('')

    const changeHandler = (dispatch: Dispatch<SetStateAction<string>>): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => {
        return (event) => {
            dispatch(event.target.value)
        }
    }

    const send = () => {
        if (user && text)
            client.service('messages').create({
                support: support._id,
                user: user._id,
                text
            }).then(() => {
                setText('')
                close()
            })
    }

    return (
        <div className="lc_body">
            <textarea className="mb-4" placeholder={t('soobsheniya')} value={text} onChange={changeHandler(setText)} />
            <div className="btn-green text-center" onClick={send}>{t('otpravit')}</div>
        </div>
    )
}

export default Chat