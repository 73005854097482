import { Paginated } from "@feathersjs/feathers"
import { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Pagination from "../components/Pagination"
import TransactionRow from "../components/TransactionRow"
import client from "../helpers/feathers"
import useUserContext from "../hooks/useUserContext"
import { ITransaction } from "../interfaces/services"

const History = () => {
    const { t } = useTranslation()
    const user = useUserContext()
    const [transactions, setTransactions] = useState<Paginated<ITransaction>>()

    useEffect(() => {
        if (user)
            load()
    }, [user])

    const load = (skip: number = 0) => {
        client
            .service('transactions')
            .find({
                query: {
                    $or: [
                        { user: user?._id },
                        { recipient: user?._id }
                    ],
                    $sort: { createdAt: -1 },
                    $skip: skip
                }
            })
            .then((r: Paginated<ITransaction>) => setTransactions(r))
    }

    return (
        <>
            <h1>{t('istoriya')}</h1>
            <div className="history_header row mt-5 justify-content-between">
                <div className="col-auto">
                    <div className="history_filter">
                        {t('filtrovat')}
                        <img src="/images/icon-settings.png" alt="" />
                    </div>
                </div>
                <div className="col-auto">
                    <div className="history_balance">
                        {t('tekushii-balans')} <span>{user ? user.balance?.toLocaleString() : 0} $</span>
                    </div>
                </div>
            </div>
            <div className="lc_table history_table">
                <div className="row lc_table_header ">
                    <div className="col-lg-2">{t('data-vremya')}</div>
                    <div className="col-lg-7">{t('nazvanie-operacii')}</div>
                    <div className="col-lg-3">{t('status')}</div>
                </div>
                <div className="row lc_table_body history_table_body">

                    {transactions?.data.map(transaction => (
                        <TransactionRow transaction={transaction} key={transaction._id} />
                    ))}
                </div>
            </div>
            {/* пагинация */}
            {transactions &&
                <Pagination state={transactions} load={load} />
            }
        </>
    )
}


export default History