import { Paginated } from "@feathersjs/feathers"
import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import client from "../../helpers/feathers"
import { IService } from "../../interfaces/services"

interface IPartnerAdvantage extends IService {
    _text: any,
    image: any
}

const previousData: IPartnerAdvantage[] = [
    
]

const PartnerAdvantages = () => {
    const [advantages, setAdvantages] = useState<IPartnerAdvantage[]>(previousData)
    const { t, i18n } = useTranslation();

    useEffect(() => {
        client.service('partner-advantages').find()
            .then((res: Paginated<IPartnerAdvantage>) => {
                if (res.total > 0)
                    setAdvantages(res.data)
            })
    }, [])

    return (
        <Row className="line">
            {advantages.map(advantage => (
                <Col xs={12} lg={3} key={advantage._id}>
                    <img src={advantage.image.src} alt="" />
                    <p dangerouslySetInnerHTML={{ __html: advantage._text[i18n.language] || ''}}></p>
                </Col>
            ))}
        </Row>
    )
}

export default PartnerAdvantages