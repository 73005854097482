import { Create, Datagrid, Edit, EditButton, ImageField, ImageInput, List, ResourceProps, SimpleForm, TextField, TextInput, TransformData, TranslatableInputs } from 'react-admin'
import { RichTextInput } from 'ra-input-rich-text';
import { API } from '../constants';
import { uploadTransform } from '../helpers/admin';
import ReviewsIcon from '@mui/icons-material/Reviews';
import { useTranslation } from 'react-i18next';

const RESOURCE: string = 'reviews'

const ReviewList = () => {
    return (
        <List
            resource={RESOURCE}

        >
            <Datagrid>
                <TextField source="name" />
                <EditButton />
            </Datagrid>
        </List>
    )
}

const ReviewCreate = () => {
    return (
        <Create transform={uploadTransform('image')}>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <TextInput source="name" fullWidth />
                <RichTextInput source="text" fullWidth />
                <ImageInput source='image'>
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}

const ReviewEdit = () => {
    const { i18n } = useTranslation()
    return (
        <Edit transform={uploadTransform('image')}>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <TranslatableInputs locales={i18n.languages as string[]} >
                    <TextInput source="_name" fullWidth />
                    <RichTextInput source="_text" fullWidth />
                </TranslatableInputs>
                <TextInput source="name" fullWidth />
                <RichTextInput source="text" fullWidth />
                <ImageInput source='image'>
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
}

export const ReviewsProps: ResourceProps = {
    name: RESOURCE,
    icon: ReviewsIcon,
    list: ReviewList,
    edit: ReviewEdit,
    create: ReviewCreate,
    options: {
        label: 'Отзывы'
    }
}