import InboxIcon from '@mui/icons-material/Inbox';
import { BooleanField, Datagrid, DateField, EditButton, EditGuesser, List, NullableBooleanInput, ResourceProps, SaveButton, SelectInput, Show, ShowButton, SimpleShowLayout, TextField, TopToolbar, useRecordContext, useUpdate } from 'react-admin';
import Button from '@mui/material/Button';
import { useEffect } from 'react';

const RESOURCE = 'requests'

const reqestsFilters = [
    <NullableBooleanInput source='status' falseLabel="Новые" trueLabel="Отвеченные" alwaysOn/>
];

const RequestList = () => {
    return (
        <List filters={reqestsFilters}>
            <Datagrid>
                <TextField source='name' />
                <TextField source='email' />
                <DateField source='createdAt' showTime />
                <BooleanField source='status' />
                <ShowButton />
            </Datagrid>
        </List>
    )
}

const RequestShowActions = () => {
    const record = useRecordContext();
    const [update, { isLoading, error }] = useUpdate()

    const customAction = () => {
        update(
            RESOURCE,
            { id:record.id,data:{status:true}, previousData:record}
        )
    }

    return (
        <TopToolbar>
            <EditButton />
            {/* Add your custom actions */}
            <Button color="primary" size='small' onClick={customAction}>Отвечено</Button>
        </TopToolbar>
    )
}

const RequestShow = () => {
    return (
        <Show actions={<RequestShowActions />}>
            <SimpleShowLayout>
                <TextField source='name' />
                <TextField source='email' />
                <DateField source='createdAt' showTime />
                <BooleanField source='status' />

            </SimpleShowLayout>
        </Show>
    )
}

export const RequestsProps: ResourceProps = {
    name: RESOURCE,
    icon: InboxIcon,
    list: RequestList,
    show: RequestShow,
    options: {
        label: 'Запросы'
    }
}