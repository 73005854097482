import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import '../../styles/_footer.scss'
import Request from '../Request'

const Footer = () => {
    const [requestShow, setRequestShow] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0)
    const { t } = useTranslation()

    const handleRequestClose = () => setRequestShow(false);
    const handleRequestShow = () => setRequestShow(true);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <footer>
            <div className="container">
                <div className="botline">
                    <div className="row align-items-center gx-lg-5 my-5">
                        <div className="col-lg-auto">
                            <div className="logo"><span>L</span>ok<span>i</span></div>
                        </div>
                        <div className="col-lg col-12 slogan">{t('dengi-dolzhny-0')}<br />{t('prinosit-dengi-0')}</div>
                        <div className="col-lg col-12 schedule"><img src="/images/clock.png" alt="" />{t('rabotaem')}
                            <span>24/5</span>
                        </div>
                        <div className="col-lg-3 col-12 text-center text-lg-start">
                            <button onClick={handleRequestShow} className="btn-green">{t('ostavit-zayavku')}</button></div>
                    </div>
                    <div className="row justify-content-between my-5 gray-text">
                        <div className="col-lg-8 col-12">{t('loki-unikalnyi-bot-avtomatizirovannoi-torgovli-na-rynke-forex-1')}<br />{t('dannaya-programma-razrabotana-dlya-polucheniya-passivnogo-dokhoda-bez-uchastiya-treidera-1')}</div>
                        {/* <div className="col-lg-auto col-6 d-none d-lg-block"><img src="/images/pay.png" alt="" /></div> */}
                    </div>
                </div>
            </div>
            <div className="botline_border">
                <div className="container">
                    <div className="botline py-4">
                        <div className="row justify-content-between gray-text">
                            <div className="col-lg-auto col-6">{t('2022-vse-prava-zashisheny')}</div>
                            <div className="col-lg-auto col-6">{t('politika-konfidencialnosti')}</div>
                        </div>
                    </div>
                </div>
            </div>
            {scrollPosition > 300 &&
                <div className='to-top' onClick={scrollToTop}>
                    ⇧
                </div>
            }
            <Modal show={requestShow} onHide={handleRequestClose} centered>
                <Modal.Body>
                    <Request close={handleRequestClose} />
                </Modal.Body>
            </Modal>
        </footer>

    )
}
export default Footer