
export const API = 'https://api.loki.expert'

export const TRANSACTION_TYPES = [
    { id: 'replenishment', name: 'Пополнение' },
    { id: 'withdrawal', name: 'Вывод' },
    { id: 'purchase', name: 'Покупка' },
    { id: 'transfer', name: 'Перевод' },
    { id: 'reward', name: 'Бонус' },
    { id: 'income', name: 'Оборот' },
    { id: 'rank', name: 'За ранг' },
];

export const TRANSACTION_STATUSES = [
    { id: 'inProgress', name: 'Ожидает' },
    { id: 'approved', name: 'Выполнена' },
    { id: 'rejected', name: 'Отклонена' },
]