import InboxIcon from '@mui/icons-material/Inbox';
import { BooleanField, Button, CloneButton, Create, CreateButton, Datagrid, DateField, DateInput, downloadCSV, Edit, EditButton, EditGuesser, ExportButton, Exporter, FunctionField, List, NullableBooleanInput, NumberField, NumberInput, ReferenceField, ReferenceInput, ResourceProps, SaveButton, SelectInput, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TopToolbar, useListContext, useRecordContext, useUpdate } from 'react-admin';
import { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MuiTextField from '@mui/material/TextField';
import IconEvent from '@mui/icons-material/Event';
import IncomesTable from '../components/IncomesTable';
import moment from 'moment';
import jsonexport from 'jsonexport/dist';
import {Box} from '@mui/material'
import PammTable from '../components/PammTable';

const RESOURCE = 'incomes'

const exporter: Exporter = (records, fetchRelatedRecords) => {
    // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
    fetchRelatedRecords(records, 'purchase', 'purchases').then(purchase => {
        const prev = records.map((record: any) => ({
            ...record,
            tradingAccount: purchase[record.purchase].tradingAccount,
            referal: purchase[record.purchase].user,
        }));


        fetchRelatedRecords(prev, 'referal', 'users').then(users => {


            const data = prev.map((record: any) => ({
                id: record._id,
                tradingAccount: record.tradingAccount,
                referal: `${users[record.referal].firstName} ${users[record.referal].lastName}`,
                amount: record.amount,
                date: moment(record.date).format('YYYY-MM')
            }))
            return jsonexport(data, {}, (err, csv) => {
                downloadCSV(csv, 'transactions');
            });
        })
    });
};

const Empty = () => {
    const [open, setOpen] = useState(false)
    const [openPamm, setOpenPamm] = useState(false)

    return (
        <Box textAlign="center" m={1}>
            <Button
                onClick={() => setOpen(true)}
                label="Добавить отчет"
            >
                <IconEvent />
            </Button>
            <Button
                onClick={() => setOpenPamm(true)}
                label="Добавить PAMM отчет"
            >
                <IconEvent />
            </Button>
            <IncomesTable open={open} closeHandler={() => setOpen(false)} />
            <PammTable open={openPamm} closeHandler={() => setOpenPamm(false)} />

        </Box>
    )
}

const ListActions = () => {
    const [open, setOpen] = useState(false)
    const [openPamm, setOpenPamm] = useState(false)


    return (
        <TopToolbar>
            <Button
                onClick={() => setOpen(true)}
                label="Добавить отчет"
            >
                <IconEvent />
            </Button>
            <Button
                onClick={() => setOpenPamm(true)}
                label="Добавить PAMM отчет"
            >
                <IconEvent />
            </Button>
            <CreateButton />
            <ExportButton />
            {/* Add your custom actions */}
            <IncomesTable open={open} closeHandler={() => setOpen(false)} />
            <PammTable open={openPamm} closeHandler={() => setOpenPamm(false)} />

        </TopToolbar>
    )
}

const DateFilter = () => {
    const [date, setDate] = useState<Date>()
    const { setFilters } = useListContext();

    useEffect(() => {
        if (date) {
            const filters = {
                date: {
                    $gte: moment(date).startOf('month').toISOString(),
                    $lte: moment(date).endOf('month').toISOString(),
                }
            }
            setFilters(filters, undefined)
        }
    }, [date])

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                views={['year', 'month']}
                label="Перод"
                value={date}
                onChange={(newValue) => {
                    setDate(newValue as Date);
                }}
                renderInput={(params) => <MuiTextField sx={{ mt: '20px' }} size='small' {...params} />}
            />
        </LocalizationProvider>
    )
}

const incomeFilters = [
    <DateInput label="Месяц" source="date" alwaysOn />
];

const IncomesList = () => {
    return (
        <List actions={<ListActions />} filters={<DateFilter />} exporter={exporter} empty={<Empty />}>
            <Datagrid>
                <ReferenceField link="show" source='purchase' label='Пользователь' reference='purchases'>
                    <ReferenceField link="show" source='user' reference='users'>
                        <FunctionField
                            label="Имя"
                            render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                        />
                    </ReferenceField>
                </ReferenceField>
                <ReferenceField link="show" source='purchase' label='Счет' reference='purchases'>
                    <TextField source='tradingAccount' />
                </ReferenceField>
                <DateField source='date' />
                <NumberField source='amount' />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
}



const RequestShow = () => {
    return (
        <Show>
            <SimpleShowLayout>
                {/* <ReferenceField link="show" source='user' label='Пользователь' reference='users'>
                    <TextField source='email' />
                </ReferenceField> */}
                <ReferenceField link="show" source='purchase' label='Счет' reference='purchases'>
                    <TextField source='tradingAccount' />
                </ReferenceField>
                <DateField source='date' />
                <NumberField source='amount' />
            </SimpleShowLayout>
        </Show>
    )
}

const IncomesEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                {/* <ReferenceInput source='user' label='Пользователь' reference='users'>
                    <SelectInput optionText='email' />
                </ReferenceInput> */}
                <ReferenceInput source='purchase' label='Счет' reference='purchases'>
                    <SelectInput optionText='tradingAccount' />
                </ReferenceInput>
                <DateInput source='date' />
                <NumberInput source='amount' />
            </SimpleForm>
        </Edit>
    )
}

const IncomesCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <ReferenceInput source='user' label='Пользователь' reference='users'>
                    <SelectInput optionText='email' />
                </ReferenceInput>
                <ReferenceInput source='purchase' label='Счет' reference='purchases'>
                    <SelectInput optionText='tradingAccount' />
                </ReferenceInput>
                <DateInput source='date' />
                <NumberInput source='amount' />
            </SimpleForm>
        </Create>
    )
}

export const IncomesProps: ResourceProps = {
    name: RESOURCE,
    // icon: InboxIcon,
    list: IncomesList,
    show: RequestShow,
    edit: IncomesEdit,
    create: IncomesCreate,
    options: {
        label: 'Начисления'
    }
}