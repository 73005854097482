import { ChangeEventHandler, Dispatch, SetStateAction, useEffect, useState } from "react"
import { Form, Modal, Tab, Tabs } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import TwoFactor from "../components/TwoFactor"
import client from "../helpers/feathers"
import useNotificationContext from "../hooks/useNotificationContext"
import useUserContext from "../hooks/useUserContext"


const Profile = () => {
    const { t } = useTranslation()

    return (

        <div className="lc_settings">
            <h1>{t('nastroiki')}</h1>
            <div className="lc_tabs">
                <Tabs defaultActiveKey="common" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="common" title={t('obshie')}>
                        <Common />
                    </Tab>
                    <Tab eventKey="security" title={t('bezopasnost')}>
                        <Security />
                    </Tab>
                    <Tab eventKey="notifications" title={t('uvedomleniya')}>
                        <Notifications />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

const Common = () => {
    const { t } = useTranslation()
    const user = useUserContext()
    const { addNotification } = useNotificationContext()
    const [tether, setTether] = useState(user?.tether || '')
    const [isChanged, setIsChanged] = useState(false)

    useEffect(() => {
        if (user) {
            setTether(user.tether || '')
        }
    }, [user])

    useEffect(() => {
        if (tether !== user?.tether)
            setIsChanged(true)
        else
            setIsChanged(false)
    }, [user, tether])

    const changeHandler = (dispatch: Dispatch<SetStateAction<string>>): ChangeEventHandler<HTMLInputElement> => {
        return (event) => {
            dispatch(event.target.value)
        }
    }

    const save = () => {
        const data: any = {}
        if (tether !== user?.tether)
            data['tether'] = tether

        if (user && Object.keys(data).length > 0)
            client.service('users').patch(user._id, data)
                .then(() =>
                    addNotification({
                        id: new Date().getTime().toString(36),
                        text: t('sokhraneno')
                    }, 2000)
                )
    }

    return (
        <>
            {/* личные данные */}
            <div className="row">
                <div className="col-12 col-lg-4">
                    <div className="lc_settings_info">
                        <div className="lc_settings_label">{t('familiya')}</div>
                        <div className="lc_settings_value">{user?.lastName}</div>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="lc_settings_info">
                        <div className="lc_settings_label">{t('imya')}</div>
                        <div className="lc_settings_value">{user?.firstName}</div>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="lc_settings_info">
                        <div className="lc_settings_label">{t('otchestvo')}</div>
                        <div className="lc_settings_value">{user?.middleName}</div>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="lc_settings_info">
                        <div className="lc_settings_label">{t('elektronnaya-pochta')}</div>
                        <div className="lc_settings_value">{user?.email}</div>
                    </div>
                </div>
            </div>
            {/* платежные системы */}
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="lc_settings_field">
                        <div className="lc_settings_label">{t('usdt-trc-20')}</div>
                        <input type="text" className="lc_settings_input" placeholder={t('vvedite-vash-nomer-koshelka')} value={tether} onChange={changeHandler(setTether)} />
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4 mt-4">
                <button
                    className="btn-green lc_body_btn"
                    onClick={save}
                    disabled={!isChanged}
                >
                    {t('sokhranit')}
                </button>
            </div>
        </>
    )
}

const Security = () => {
    const { t } = useTranslation()
    const user = useUserContext()
    const { addNotification } = useNotificationContext()
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [checkPassword, setCheckPassword] = useState('')
    const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false)
    const [show2fa, setShow2fa] = useState(false)
    const [is2fa, setIs2fa] = useState(false)
    const [isChanged, setIsChanged] = useState(false)

    useEffect(() => {
        if (user)
            setIsTwoFactorEnabled(user.isTwoFactorEnabled || false)
    }, [user])

    useEffect(() => {
        if (isTwoFactorEnabled === true && user && user.isTwoFactorEnabled === false)
            setShow2fa(true)
    }, [isTwoFactorEnabled])

    useEffect(() => {
        if (show2fa === false && is2fa === false)
            setIsTwoFactorEnabled(false)
    }, [show2fa])

    useEffect(() => {
        if (
            (!!oldPassword && !!newPassword && !!checkPassword) ||
            user?.isTwoFactorEnabled !== isTwoFactorEnabled
        )
            setIsChanged(true)
        else
            setIsChanged(false)
    }, [user, oldPassword, newPassword, checkPassword, isTwoFactorEnabled])

    const changeHandler = (dispatch: Dispatch<SetStateAction<string>>): ChangeEventHandler<HTMLInputElement> => {
        return (event) => {
            dispatch(event.target.value)
        }
    }

    const save = () => {
        if (user && isTwoFactorEnabled !== user.isTwoFactorEnabled)
            client
                .service('users')
                .patch(user?._id, {
                    isTwoFactorEnabled: isTwoFactorEnabled
                })
        if (newPassword && checkPassword && newPassword === checkPassword)
            client
                .service('authentication')
                .create({
                    strategy: 'local',
                    email: user?.email,
                    password: oldPassword
                })
                .then(() => {
                    client
                        .service('users')
                        .patch(user?._id, {
                            password: newPassword
                        })
                        .then(() => {
                            setOldPassword('')
                            setNewPassword('')
                            setCheckPassword('')

                            addNotification({
                                id: new Date().getTime().toString(36),
                                text: t('sokhraneno-0')
                            }, 2000)

                        })
                        .catch(() => console.log('error'))
                })
                .catch(() => console.log('error'))
    }

    return (
        <>
            {/* пароль */}
            <div className="row">
                <div className="col-12">
                    <div className="lc_settings_field mb-0">
                        <div className="lc_settings_label">{t('izmenenie-parolya')}</div>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="lc_settings_field my-0">
                        <input
                            type="password"
                            className="lc_settings_input"
                            placeholder={t('tekushii-parol')}
                            value={oldPassword}
                            onChange={changeHandler(setOldPassword)}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="lc_settings_field my-0">
                        <input
                            type="password"
                            className="lc_settings_input"
                            placeholder={t('novyi-parol')}
                            value={newPassword}
                            onChange={changeHandler(setNewPassword)}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="lc_settings_field my-0">
                        <input
                            type="password"
                            className="lc_settings_input"
                            placeholder={t('podtverdite-parol')}
                            value={checkPassword}
                            onChange={changeHandler(setCheckPassword)}
                        />
                    </div>
                </div>
            </div>
            {/* Двухфакторная авторизация */}
            <div className="row mt-4">
                <div className="col-12">
                    <div className="lc_settings_field">
                        <div className="lc_settings_label">{t('dvukhfaktornaya-avtorizaciya-cherez-google')}</div>
                        <div className="d-flex lc_settings_checkbox_wrap">
                            <input
                                type="checkbox"
                                className="lc_settings_checkbox form-check-input"
                                checked={isTwoFactorEnabled}
                                onChange={(e) => setIsTwoFactorEnabled(e.target.checked)}
                            />
                            <div className="lc_settings_value ms-3">
                                {t('podklyuchenie-dvukhfaktornoi-avtorizacii-cherez-google')}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-12">
                    <div className="g2a_title">{t('skachat-google-authenticator')}</div>
                </div>
                <div className="col-6">
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" className="g2a_button">
                        <img src="/images/g2aGoogle.png" alt="" />
                    </a>
                </div>
                <div className="col-6">
                    <a href="https://apps.apple.com/ru/app/google-authenticator/id388497605" target="_blank" className="g2a_button">
                        <img src="/images/g2aApple.png" alt="" />
                    </a>
                </div>
                <div className="col-12 col-lg-4 mt-4">
                    <button
                        className="btn-green lc_body_btn"
                        onClick={save}
                        disabled={!isChanged}
                    >
                        {t('sokhranit-0')}
                    </button>
                </div>
            </div>
            <Modal show={show2fa} onHide={() => setShow2fa(false)} centered>
                <Modal.Body>
                    <TwoFactor close={() => { setShow2fa(false); setIs2fa(true) }} />
                </Modal.Body>
            </Modal>
        </>
    )
}

const Notifications = () => {
    const { t } = useTranslation()
    const user = useUserContext()
    const { addNotification } = useNotificationContext()
    const [emailNotification, setEmailNotification] = useState(false)
    const [telegramNotification, setTelegramNotification] = useState(false)
    const [telegramId, setTelegramId] = useState('')
    const [isChanged, setIsChanged] = useState(false)

    useEffect(() => {
        if (user) {
            setEmailNotification(user.emailNotification || false)
            setTelegramNotification(user.telegramNotification || false)
            setTelegramId(user.telegram || '')
        }
    }, [user])
    useEffect(() => {
        if (
            user?.emailNotification !== emailNotification ||
            user?.telegramNotification !== telegramNotification ||
            user?.telegram !== telegramId
        )
            setIsChanged(true)
        else
            setIsChanged(false)
    }, [user, emailNotification, telegramNotification, telegramId])

    const save = () => {
        const data: any = {}

        if (emailNotification != user?.emailNotification)
            data['emailNotification'] = emailNotification
        if (telegramNotification != user?.telegramNotification)
            data['telegramNotification'] = telegramNotification
        if (telegramId != user?.telegram)
            data['telegram'] = telegramId

        if (user && Object.keys(data).length > 0)
            client.service('users').patch(user._id, data)
                .then(() =>
                    addNotification({
                        id: new Date().getTime().toString(36),
                        text: t('sokhraneno-1')
                    }, 2000)
                )
    }

    return (
        <>
            {/* Уведомления */}
            <div className="row">
                <div className="col-12">
                    <div className="d-flex my-4 lc_settings_checkbox_wrap">
                        <input
                            type="checkbox"
                            className="lc_settings_checkbox form-check-input"
                            checked={telegramNotification}
                            onChange={(e) => setTelegramNotification(e.target.checked)}
                        />
                        <div className="lc_settings_value ms-3">
                            {t('otpravlyat-uvedomleniya-v-telegram')}
                        </div>
                    </div>
                    <div className="d-flex my-4 lc_settings_checkbox_wrap">
                        <input
                            type="checkbox"
                            className="lc_settings_checkbox form-check-input"
                            checked={emailNotification}
                            onChange={(e) => setEmailNotification(e.target.checked)}
                        />
                        <div className="lc_settings_value ms-3">
                            {t('otpravlyat-uvedomleniya-po-email')}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <p>
                        {t('kak-uznat-svoi-chat-id-v-telegram-napishite')} <a href="https://t.me/LokiYour_bot" target="_blank">@LokiYour_bot</a>{t('v-otvete-on-prishlet-vash-id')}
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-4">
                    <div className="lc_settings_field">
                        <div className="lc_settings_label">{t('telegram-chat-id')}</div>
                        <input
                            type="text"
                            className="lc_settings_input"
                            placeholder={t('tekushii-chat-id')}
                            value={telegramId}
                            onChange={(e) => setTelegramId(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-4 mt-4">
                    <button
                        className="btn-green lc_body_btn"
                        onClick={save}
                        disabled={!isChanged}
                    >
                        {t('sokhranit-1')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default Profile