import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import client from "../../helpers/feathers"
import useUserContext from "../../hooks/useUserContext"
import LanguageSwitcher from "../LanguageSwitcher"

const AccountHeader = () => {
    const { t } = useTranslation()
    const user = useUserContext()
    const navigate = useNavigate()

    const logout = async () => {
        await client.logout()
        localStorage.removeItem('permissions')
        navigate('/', { replace: true })
    }

    return (
        <header>
            {user && user.roles?.includes('admin') &&
                <div className="container">
                    <a href="/admin">{t('upravlenie')}</a>
                </div>
            }
            <div className="container">
                <div className="row align-items-center justify-content-around py-4">
                    <div className="col-6 col-lg">
                        <div className="topline_balance">
                            <div className="topline_balance_title">{t('balans')}</div>
                            <div className="topline_balance_value">{user && user.balance?.toLocaleString() || 0} <span>$</span></div>
                        </div>
                    </div>
                    <div className="col-6 col-lg text-center">
                        <a href="/" className="logo "><span>L</span>ok<span>i</span></a>
                    </div>
                    <div className="col-12 col-lg mt-4">
                        <div className="topline__reg topline_lc">
                            <button onClick={logout}>
                                <img src="/images/unlog.png" alt="" />
                                {t('vyiti')}
                            </button>
                            <Link to='/account/profile'><img src="/images/reg.png" alt="" />{t('moi-profil')}</Link>
                            <LanguageSwitcher/>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default AccountHeader