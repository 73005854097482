import { useEffect } from "react";
import { CloneButton, Create, Datagrid, DateField, Edit, EditButton, FunctionField, List, ReferenceField, ReferenceInput, ReferenceManyField, ResourceProps, SaveButton, SaveButtonProps, SelectInput, Show, ShowButton, SimpleForm, SimpleShowLayout, Tab, TabbedShowLayout, TextField, TextInput, useAuthProvider, useGetIdentity, usePermissions, useRecordContext, useRedirect } from "react-admin"
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

const RESOURCE: string = 'supports'

const CreateMessage = () => {
    const record = useRecordContext()
    const { identity } = useGetIdentity()

    if (identity)
        return (
            <CloneButton
                resource="messages"
                label="Ответить"
                record={{ support: record.id, user: identity.id }}
            />
        )
    else
        return null
};

// const Save = (props: SaveButtonProps) => {

//     const redirect = useRedirect();
//     const onSuccess = (response) => {

//         redirect('/posts');
//     };
//     return <SaveButton {...props} transform />;
// }

export const SupportList = () => {
    return (
        <List resource={RESOURCE}>
            <Datagrid>
                <TextField source='theme' />
                <ReferenceField link="show" source='user' reference='users'>
                    <FunctionField
                        label="Имя"
                        render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                    />
                </ReferenceField>
                <DateField source='createdAt' showTime />
                {/* <Date source='createdAt' /> */}
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    )
}

export const SupportShow = () => {
    return (
        <Show>
            <SimpleShowLayout >

                <TextField source='id' label='ID' />
                <TextField source='theme' label='Тема' />
                <TextField source='text' label='Обращения' />
                <ReferenceField link="show" source='user' reference='users' label='Пользователь'>
                    <FunctionField
                        label="Имя"
                        render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                    />
                </ReferenceField>
                <TextField source='status' label='Статус' />
                <ReferenceManyField
                    reference='messages'
                    target='support'
                    label='Сообщения'
                >
                    <Datagrid>
                        <TextField source='text' />
                        <ReferenceField link="show" source='user' reference='users'>
                            <FunctionField
                                label="Имя"
                                render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                            />
                        </ReferenceField>
                        <DateField source='createdAt' />
                    </Datagrid>
                </ReferenceManyField>
                <CreateMessage />
            </SimpleShowLayout>
        </Show>
    )
}

export const SupportEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <TextInput disabled source="id" />
                <ReferenceInput source='user' reference='users'>
                    <SelectInput optionText="email" disabled />
                </ReferenceInput>
                <TextInput source='theme' multiline />
                <TextInput source='text' multiline />
                <SelectInput source='status' choices={[
                    { id: 'open', name: 'Открыта' },
                    { id: 'inProgress', name: 'В обработке' },
                    { id: 'approved', name: 'Закрыта' },
                    { id: 'rejected', name: 'Отклонена' },
                ]}

                />
            </SimpleForm>
        </Edit>
    )
}

export const SupportCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <TextInput disabled source="id" />
                <ReferenceInput source='user' reference='users'>
                    <SelectInput optionText="email" disabled />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
}


export const SupportProps: ResourceProps = {
    name: RESOURCE,
    icon: ContactSupportIcon,
    list: SupportList,
    edit: SupportEdit,
    show: SupportShow,
    options: {
        label: 'Техническая поддержка',
        sub: 'support'
    }
}