import { Paginated } from "@feathersjs/feathers"
import { useEffect, useState } from "react"

interface PaginationProps {
    state: Paginated<any>,
    load: Function
}

const Pagination = ({ state, load }: PaginationProps) => {
    const pages = Math.ceil(state.total / state.limit)
    const current = (state.skip / state.limit)
    const [buttons, setButtons] = useState<JSX.Element[]>([])

    useEffect(() => {
        const arr: JSX.Element[] = []
        if (state.total > state.limit) {
            for (let i = 0; i < pages; i++) {
                arr.push(
                    <div onClick={() => load(state.limit * i)} className={`pagination_item ${current === i && 'active'}`} key={i}>
                        {i + 1}
                    </div>)
            }
        }
        setButtons(arr)
    }, [state])

    return (
        <div className="pagination">
            {buttons}
        </div>
    )

}

export default Pagination