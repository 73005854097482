import { Col, Container, Modal, Row, Toast, ToastContainer } from "react-bootstrap"
import { Outlet, useNavigate } from "react-router-dom"
import AccountHeader from "./AccountHeader"
import AccountSideBar from "./AccountSideBar/AccountSideBar"
import '../styles/account.scss'
import { useCallback, useEffect, useState } from "react"
import client from "../helpers/feathers"
import userContext from "../contexts/userContext"
import { useLogin } from "../hooks/useLogin"
import { IUser } from "../interfaces/services"
import LoginForm from "./LoginForm"

const Provider = userContext.Provider

const AccountWrapper = () => {
    const [user, setUser] = useState<IUser | undefined>()
    const login = useLogin()
    const navigate = useNavigate()

    useEffect(() => {
        if (login === true) {
            client.get('authentication').then((a: { user: IUser }) => {
                setUser(a.user)
            })
        }
        if (login === false)
            navigate('/login')
    }, [login])

    useEffect(() => {
        if (user)
            client.service('users').on('patched', update);
        return () => client.service('users').removeListener('patched', update);
    }, [user])

    const update = useCallback((u: IUser) => {
        if (user && user._id === u._id)
            setUser(u)
    }, [user])

    if (login)
        return (
            <Provider value={user}>
                <AccountHeader />
                <section className="mt-5">
                    <Container>
                        <Row>
                            <Col xs={12} lg={3} className="sidebar">
                                <AccountSideBar />
                            </Col>
                            <Col className="lc_body">
                                <Outlet />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Provider>
        )
    else
        return null
}
export default AccountWrapper