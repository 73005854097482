import Bundles from "../components/Bundles"
import MyPurchases from "../components/MyPurchases"

const Services = () => {
    return (
        <>
            <Bundles col={4} />
            <MyPurchases />
        </>
    )
}

export default Services