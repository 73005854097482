import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import rest from '@feathersjs/rest-client';
import authentication from '@feathersjs/authentication-client';
import { API } from '../constants';

const socket = io(API)
// const rest = restcli(API)

const client = feathers()
// export const restApp = feathers()

client.configure(socketio(socket))
client.configure(authentication())
// restApp.configure(rest(API).fetch(fetch))
// restApp.configure(authentication())

export default client