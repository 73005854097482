import { useTranslation } from "react-i18next"

const Downloads = () => {
    const { t } = useTranslation()

    return (
        <>
            <h1>Скачать</h1>
            <div className="row g-4">
                <div className="col-12 col-lg-6 col-lg-4">
                    <div className="education_card">
                        <div className="education_title">
                            Для windows
                        </div>
                        <a className="education_link" target="_blank" href={'/educations/just2trade4setup (2).exe'}>
                            {t('skachat')}
                        </a>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-lg-4">
                    <div className="education_card">
                        <div className="education_title">
                            Для macOS
                        </div>
                        <a className="education_link" target="_blank" href={'/educations/MetaTrader4 (1).dmg'}>
                            {t('skachat-0')}
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Downloads