import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import About from '../components/About'
import Advantages from '../components/Advantages'
import Audience from '../components/Audience'
import Bundles from '../components/Bundles'
import Faqs from '../components/Faqs'
import Impacts from '../components/Impacts'
import PartnerAdvantages from '../components/PartnerAdvantages'
import Reviews from '../components/Reviews'
import Stages from '../components/Stages'
import { createUsers } from '../helpers/fake'
import { IUser } from '../interfaces/services'
import '../styles/homePage.scss'

const Home = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    return (
        <>
            <section>
                <div className="container">
                    <div className="baner">
                        <div className="row">
                            <div className="col-lg-4 col-12"><img src="/images/bot1.png" alt="" /></div>
                            <div className="col ps-md-5">
                                <div className="slogan">{t('vashi-dengi-dolzhny-0')}<br /> {t('prinosit-vam-dokhod-0')}</div>
                                <div className="text">{t('s-nami-vy-mozhete-poluchat-ezhemesyachnyi-dokhod-0')}<br /> {t('ot-3-do-15-ezhemesyachno-0')}</div>
                                <button onClick={() => navigate('/account')} className="btn-trans">{t('poluchit-dostup-1')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="s-about">
                <div className="container">
                    <h2 className="h2">{t('o-bote-0')}<span>01</span></h2>
                    <p>{t('loki-unikalnyi-bot-avtomatizirovannoi-torgovli-na-rynke-forex-0')}<br />{t('dannaya-programma-razrabotana-dlya-polucheniya-passivnogo-dokhoda-bez-uchastiya-treidera-0')}</p>
                    <About />
                </div>
            </section>
            <section className="accept">
                <div className="container">
                    <h2>{t('dengi-dolzhny-0')}<br /><span>{t('prinosit-dengi-0')}</span></h2>
                    <p>{t('pokupaite-nashego-bota-i-poluchaite-stabilnyi-0')} <br />{t('passivnyi-dokhod-0')}</p>
                    <button onClick={() => navigate('/account')} className="btn-trans">{t('poluchit-dostup-2')}</button>
                </div>
            </section>
            <section>
                <div className="container">
                    <h2 className="h2">{t('preimushestva-0')}<br />{t('nashego-sovetnika-0')} <span>02</span></h2>
                    <Advantages />
                </div>
            </section>
            <section>
                <div className="container">
                    <h2 className="h2">{t('dlya-kogo-podoidet-0')}<br />{t('nash-sovetnik-0')}<span>03</span></h2>
                    <p className="mt-5">{t('nash-torgovyi-sovetnik-podoidet-dlya-vsekh-kto-khochet-poluchat-pribyl-0')}
                    </p>
                    <Audience />
                </div>
            </section>
            <section id="s-price">
                <div className="container">
                    <h2 className="h2">{t('stoimost-sovetnikov-0')}<span>04</span></h2>
                    <Bundles />
                </div>
            </section>
            <section id="s-affiliate">
                <div className="container">
                    <h2 className="h2">{t('partnerskaya-programma-0')}<span>05</span></h2>
                    <p>{t('kazhdyi-klient-kotoryi-kupil-sovetnika-u-nas-imeet-vozmozhnost-pouchastvovat-v-partnerskoi-programme-0')}
                    </p>
                    <PartnerAdvantages />
                </div>
            </section>
            <section className="impact">
                <div className="container">
                    <h2 className="h2">{t('chto-poluchaet-klient-0')}<br />{t('pokupaya-sovetnika-0')}<span>06</span></h2>
                    <Impacts />
                </div>
            </section>
            <section id="s-stages">
                <div className="container">
                    <h2 className="h2">{t('podklyuchenie-0')}<span>07</span></h2>
                    <p>{t('dlya-togo-chtoby-nachat-rabotat-s-nami-ne-nuzhno-prokhodit-mnozhestvo-razlichnykh-neponyatnykh-etapov-i-net-neobkhodimosti-sovershat-sotni-razlichnykh-deistvii-s-nami-rabotat-prosto-i-ponyatno-0')}</p>
                    <Stages />
                </div>
            </section>
            {i18n.language === 'ru' &&
                <section>
                    <div className="container">
                        <h2 className="h2">{t('otzyvy-o-nas-0')}<span>08</span></h2>
                        <Reviews />
                    </div>
                </section>
            }
            <section id="s-faq" className="mb-0">
                <div className="container">
                    <h2 className="h2">{t('voprosy-otvety-0')}<span>{i18n.language === 'ru'?'09':'08'}</span></h2>
                    <Faqs />
                </div>
            </section>
            <section id="s-contacts" className="accept accept_2">
                <div className="container">
                    <h2>{t('ostalis-voprosy-0')}</h2>
                    <p>{t('pishite-nam-na-pochtu-0')} <a href="mailto:Support@loki.expert">Support@loki.expert</a></p>
                    <p>Или в <a href="https://t.me/AlgoTraderLoki" target='_blank'>телеграм</a></p>
                </div>
            </section>
            <section className="risk m-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-auto risk_1">!</div>
                        <div className="col">{t('torgovlya-soprovozhdaetsya-sushestvennym-riskom-i-ne-podkhodit-kazhdomu-investoru-investor-mozhet-poteryat-vse-ili-bolshe-chem-pervonachalnye-investicii-proshlye-pokazateli-ne-yavlyayutsya-garantiei-budushikh-rezultatov-komanda-loki-yavlyaetsya-razrabotchikom-i-ne-zanimaetsya-upravleniem-sredstvami-0')}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Home 