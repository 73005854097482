import { Outlet, Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AccountWrapper from "./components/AccountWrapper";
import PageWrapper from "./components/PageWrapper";
import languageContext, { defaultLanguage } from "./contexts/languageContext";
import Account from "./pages/Account";
import Admin from "./pages/Admin";
import Affiliate from "./pages/Affiliate";
import Chat from "./pages/Chat";
import Education from "./pages/Education";
import History from "./pages/History";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Promotions from "./pages/Promotions";
import Services from "./pages/Services";
import Structure from "./pages/Structure";
import Support from "./pages/Support";
import 'moment/locale/ru';
import Withdrawal from "./pages/Withdrawal";
import Replenishment from "./pages/Replenishment";
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import { Modal } from "react-bootstrap";
import NotificationWrapper from "./components/NotificationWrapper";
import { YMInitializer } from "react-yandex-metrika";
import promoContext from "./contexts/promoContext";
import { useEffect, useState } from "react";
import Activate from "./pages/Activate";
import ResetForm from "./components/ResetForm";
import './i18n';
import Downloads from "./pages/Downloads";

const Provider = languageContext.Provider
const PromoProvider = promoContext.Provider

function App() {
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams();
  const [promo, setPromo] = useState('')

  useEffect(() => {
    const code = params.get('code')

    if (code)
      setPromo(code)
  }, [params])

  return (
    <Provider value={defaultLanguage}>
      <PromoProvider value={promo}>
        {/* яндекс метрика */}
        <YMInitializer accounts={[88965062]} />
        <NotificationWrapper>
          <Routes>
            <Route path="/" element={<PageWrapper />} >
              <Route index element={<Home />} />
            </Route>
            <Route path="/account" element={<AccountWrapper />} >
              <Route index element={<Account />} />
              <Route path='profile' element={<Profile />} />
              <Route path='services' element={<Services />} />
              <Route path='structure' element={<Structure />} />
              <Route path='affiliate' element={<Affiliate />} />
              <Route path='history' element={<History />} />
              <Route path='support' element={<Outlet />} >
                <Route index element={<Support />} />
                <Route path=':id' element={<Chat />} />
              </Route>
              <Route path='promotions' element={<Promotions />} />
              <Route path='education' element={<Education />} />
              <Route path='replenishment' element={<Replenishment />} />
              <Route path='withdrawal' element={<Withdrawal />} />
              <Route path='downloads' element={<Downloads />} />
            </Route>
            <Route path="/admin/*" element={<Admin />} >
            </Route>
            <Route path="/activate/:id" element={<Activate />} />
            <Route
              path='/login'
              element={
                <Modal show={true} onHide={() => navigate('/')} centered>
                  <Modal.Body>
                    <LoginForm />
                  </Modal.Body>
                </Modal>
              }
            />
            <Route
              path='/register'
              element={
                <Modal show={true} onHide={() => navigate('/')} centered>
                  <Modal.Body>
                    <RegisterForm />
                  </Modal.Body>
                </Modal>
              }
            />
            <Route
              path='/reset'
              element={
                <Modal show={true} onHide={() => navigate('/')} centered>
                  <Modal.Body>
                    <ResetForm />
                  </Modal.Body>
                </Modal>
              }
            />
          </Routes>
        </NotificationWrapper>
      </PromoProvider>
    </Provider >
  );
}

export default App;
