import { Paginated } from "@feathersjs/feathers"
import { Card, CardContent, CardHeader, List, ListItem, ListItemText } from "@mui/material"
import { useEffect, useState } from "react"
import { API } from "../../constants"
import client from "../../helpers/feathers"

const userService = client.service('users')

const Users = () => {
    const [totalUsers, setTotalUsers] = useState(0)
    const [activeUsers, setActiveUsers] = useState(0)
    const [onlineUsers, setOnlineUsers] = useState(0)

    useEffect(() => {
        userService
            .find()
            .then((res: Paginated<{}>) => setTotalUsers(res.total))
        userService
            .find({ query: { status: true } })
            .then((res: Paginated<{}>) => setActiveUsers(res.total))
        fetch(`${API}/online`)
            .then(res => res.json())
            .then((data: any) => setOnlineUsers(data.online))
    }, [])

    return (
        <Card>
            <CardHeader title="Пользователи" />
            <CardContent>
                <List>
                    <Item label="Всего" value={totalUsers}/>
                    <Item label="Активированных" value={activeUsers}/>
                    <Item label="Онлайн" value={onlineUsers}/>
                </List>
            </CardContent>
        </Card>
    )
}

const Item = ({ label, value }: { label: string, value: number | string }) => {
    return (
        <ListItem>
            <ListItemText primary={label} />
            <ListItemText primary={value} sx={{ textAlign: 'end' }} />
        </ListItem>
    )
}

export default Users