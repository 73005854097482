import { useEffect, useState } from "react";
import client from "../../helpers/feathers"
import { API } from '../../constants'
import { Col, Row } from "react-bootstrap";
import useUserContext from "../../hooks/useUserContext";
import { useTranslation } from "react-i18next";

interface TwoFactorProps {
    close: Function
}

const TwoFactor = ({ close }: TwoFactorProps) => {
    const { t } = useTranslation()
    const user = useUserContext()
    const [base32, setBase32] = useState('')
    const [qr, setQr] = useState('')
    const [code, setCode] = useState('')

    useEffect(() => {
        if (user) {
            const userName = (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}` : user.email
            fetch(`${API}/two-factor?user=${userName}`)
                .then((r) => r.json().then((data) => {
                    setBase32(data.base32)
                    setQr(data.google_auth_qr)
                }))
        }
    }, [user])

    const save = () => {
        fetch(`${API}/two-factor`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ base32, code })
        }).then((response) => response.json().then((data) => {
            if (data.result === true && user)
                client.service('users').patch(user._id, { twoFactorAuthenticationCode: base32, isTwoFactorEnabled: true })
                    .then(() => close())
        }))
    }

    return (
        <div className="lc_body" style={{ textAlign: 'center' }}>
            <Row>
                <Col xs={12}>
                    <img src={qr} style={{ margin: '20px auto' }} />
                </Col>
                <Col xs={12}>
                    <input
                        type="text"
                        className="lc_settings_input"
                        placeholder={t('kod-iz-google-authenticator')}
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </Col>
                <Col xs={12} className=" mt-4">
                    <button className="btn-green lc_body_btn" style={{ width: '100%' }} onClick={save}>{t('sokhranit-1')}</button>
                </Col>
            </Row>
        </div>
    )
}

export default TwoFactor