import { useEffect, useState } from "react"
import client from "../helpers/feathers"

export const useLogin = () => {
    const [login, setLogin] = useState<Boolean|undefined>()

    useEffect(() => {
        client.reAuthenticate()
            .then(() => setLogin(true))
            .catch(() => setLogin(false))
    }, [])

    return login
}