import { Link, useLocation, useMatch } from "react-router-dom"
import useUserContext from "../../hooks/useUserContext"
import MenuIcon from '@mui/icons-material/Menu';
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IMenuItem {
    title: string,
    link: string,
    icon: string
}

const accUrl = '/account'



const AccountSideBar = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const user = useUserContext()
    const [menuShow, setMenuShow] = useState(false)

    const menu: IMenuItem[] = [
        {
            title: t('glavnaya'),
            link: accUrl,
            icon: '/images/home.png'
        },
        {
            title: t('uslugi'),
            link: `${accUrl}/services`,
            icon: '/images/services.png'
        },
        {
            title: t('struktura'),
            link: `${accUrl}/structure`,
            icon: '/images/structure.png'
        },
        {
            title: t('partnyorskaya-programma'),
            link: `${accUrl}/affiliate`,
            icon: '/images/affiliateprogram.png'
        },
        {
            title: t('istoriya'),
            link: `${accUrl}/history`,
            icon: '/images/history.png'
        },
        {
            title: t('tekhpodderzhka'),
            link: `${accUrl}/support`,
            icon: '/images/support.png'
        },
        {
            title: t('akcii'),
            link: `${accUrl}/promotions`,
            icon: '/images/stock.png'
        },
        {
            title: t('obuchenie'),
            link: `${accUrl}/education`,
            icon: '/images/education.png'
        },
        {
            title: t('skachat'),
            link: `${accUrl}/downloads`,
            icon: '/images/services.png'
        },
    ]

    const isMobile = window.matchMedia('max-width-992px').matches
    console.log(isMobile);

    useEffect(() => {
        setMenuShow(false)
    }, [location])

    return (
        <>
            <div className="profil">
                <div className="profil_img"><img src="/images/ava.png" alt="" /></div>
                <div>
                    <div className="profil_rang">{user && user.rank || ''}</div>
                    <div className="profil_name">{user && user.firstName} <br />{user && user.lastName}</div>
                    <div className="profil_mail">{user && user.email}</div>
                </div>
            </div>
            {/* <nav> */}
            {isMobile ?
                <>
                    <button className="btn-green" onClick={() => setMenuShow(true)}>
                        <MenuIcon />
                        {t('menyu')}
                    </button>
                    <Modal show={menuShow} fullscreen={true} onHide={() => setMenuShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('menyu-0')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="sidebar">
                                {menu.map((item) => (
                                    <SideBarLink item={item} key={item.title} />
                                ))}
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
                :
                <>
                    {menu.map((item) => (
                        <SideBarLink item={item} key={item.title} />
                    ))}
                    <div style={{color:"#ffffff", fontSize:'1.1rem'}}>
                        По всем вопросам можете обращаться в наш <a href="https://t.me/AlgoTraderLoki" target="_blank" className="neon-text" style={{ fontSize:'1.1rem'}}>телеграм</a>
                    </div>
                </>
            }
            {/* </nav> */}
        </>
    )
}

const SideBarLink = ({ item }: { item: IMenuItem }) => {
    const match = useMatch({ path: item.link })
    return (
        <Link to={item.link} className={match ? 'active' : ''}>
            <img src={item.icon} alt={item.title} />
            {item.title}
        </Link>
    )
}

export default AccountSideBar