import { Fragment, ReactNode, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import client from "../../helpers/feathers"
import { IService } from "../../interfaces/services"

interface IAdvantage extends IService {
    title: string,
    _title: any,
    text?: string,
    _text: any,
    image?: any
}

interface ItemProps {
    advantage: IAdvantage
}

const Advantages = () => {
    const [advantages, setAdvantages] = useState<IAdvantage[]>([])
    const { t, i18n } = useTranslation();

    useEffect(() => {
        client.service('advantages').find({ query: { $limit: 50 } })
            .then((res: { data: IAdvantage[] }) => setAdvantages(res.data as IAdvantage[]))
    }, [])


    const Item = ({ advantage }: ItemProps) => {
        return (
            <Row>
                <Col lg={3}>
                    <img src={advantage.image.src || '/images/robot1.png'} alt="" />
                </Col>
                <Col lg={9}>
                    <h3>{advantage._title[i18n.language] || ''}</h3>
                    <div dangerouslySetInnerHTML={{ __html: advantage._text[i18n.language] || '' }} />
                </Col>
            </Row>
        )
    }

    return (
        <div className="advantages">
            {advantages.map(item => (
                <Item advantage={item} key={item._id} />
            ))}
        </div>
    )
}
export default Advantages