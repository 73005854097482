import { useNavigate, useParams } from "react-router-dom"
import { Dialog, DialogContent, Typography } from '@mui/material'
import { Col, Modal, Row } from "react-bootstrap"
import { useEffect, useState } from "react"
import { API } from "../constants"
import { useTranslation } from "react-i18next"

const Activate = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [status, setStatus] = useState(false)

    const closeHandler = () => navigate('/')

    const toLoginHandler = () => navigate('/login')

    useEffect(() => {
        if (id) {
            fetch(`${API}/activate?user=${id}`)
                .then(() => setStatus(true))
        }
    }, [id])

    return (
        <div>
            {status &&
                <Modal show={true} onHide={closeHandler} centered>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <p style={{ textAlign: 'center' }}>{t('vasha-uchetnaya-zapis-aktivirovana')}</p>
                            </Col>
                            <Col xs={12}>
                                <button
                                    className="btn-green"
                                    style={{ width: '100%' }}
                                    onClick={toLoginHandler}
                                >
                                    {t('voiti')}
                                </button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </div>
    )
}

export default Activate