import { Paginated } from "@feathersjs/feathers"
import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import client from "../../helpers/feathers"
import { IService } from "../../interfaces/services"

interface IImpact extends IService {
    _title: any,
    _text: any
}

const previousData: IImpact[] = [
]

const Impacts = () => {
    const [impacts, setImpacts] = useState<IImpact[]>(previousData)
    const { t, i18n } = useTranslation();

    useEffect(() => {
        client.service('impacts').find()
            .then((res: Paginated<IImpact>) => {
                if (res.total > 0)
                setImpacts(res.data)
            })
    }, [])

    return (
        <Row>
            {impacts.map((impact, index) => (
                <Col xs={12} lg={4}  key={impact._id}>
                    <div className="impact_nuber">0{index + 1}</div>
                    <h3>{impact._title[i18n.language] || ''}</h3>
                    <p dangerouslySetInnerHTML={{__html:impact._text[i18n.language]|| ''}}></p>
                </Col>
            ))}
        </Row>
    )
}

export default Impacts