import { Paginated } from "@feathersjs/feathers"
import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import client from "../../helpers/feathers"
import { IService } from "../../interfaces/services"



interface IAudience extends IService {
    text?: string,
    _text: any,
    image?: any
}



const Audience = () => {
    const [audience, setAudience] = useState<IAudience[]>([])
    const { t, i18n } = useTranslation();

    useEffect(() => {
        client.service('audiences').find()
            .then((res: Paginated<IAudience>) => {
                if (res.total > 0)
                setAudience(res.data)
            })
    }, [])

    return (
        <Row className="card_wrap">
            {audience.map(a => (
                <Col xs={12} lg={4} key={a._id}>
                    <div className="card">
                        <img src={a.image.src} alt="" />
                        <p dangerouslySetInnerHTML={{ __html: a._text[i18n.language] || ''}} />
                    </div>
                </Col>
            ))}
        </Row>
    )
}
export default Audience