import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import client from "../../helpers/feathers"

const Request = ({close}:{close:Function}) => {
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    const send = () => {
        client
        .service('requests')
        .create({
            name,
            email,
        })
        .then(()=>{
            setName('')
            setEmail('')
            close()
        })
    }

    return (
        <Row className="lc_body">
            <h2 className="my-2">{t('ostavte-zayavku')}</h2>
            <p>{t('ostavte-svoi-e-mail-i-my-obyazatelno-svyazhemsya-s-vami-v-blizhaishee-vremya')}</p>
            <Col xs={12} className="my-2">
                <input
                    type="text"
                    placeholder={t('vashe-imya')}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            </Col>
            <Col xs={12} className="my-2">
                <input
                    type="email"
                    placeholder={t('vash-email')}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
            </Col>
            <Col xs={12} className="mt-2">
                <button
                    className="btn-green"
                    style={{ width: '100%' }}
                    onClick={send}
                >
                    {t('otpravit')}
                </button>
            </Col>
        </Row>
    )
}

export default Request