import { ChangeEventHandler, useState } from "react"
import { Col, Modal, Row } from "react-bootstrap"
import Dropzone from "react-dropzone"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import client from "../helpers/feathers"
import { uploadFile } from "../helpers/uploads"
import useUserContext from "../hooks/useUserContext"

const Replenishment = () => {
    const { t } = useTranslation()
    const [file, setFile] = useState<File>()
    const [amount, setAmount] = useState<number>()
    const [modalShow, setModalShow] = useState(false)


    const user = useUserContext()
    const navigate = useNavigate()

    const handleModalOpen = () => user ? setModalShow(true) : navigate('/register')
    const handleModalClose = () => setModalShow(false)


    const send = () => {
        if (file)
            uploadFile(file)
                .then((attachment) => {
                    client
                        .service('transactions')
                        .create({
                            transactionType: 'replenishment',
                            user: user?._id,
                            amount,
                            attachment,
                        })
                        .then(() => {
                            navigate('/account/history')
                        })
                })
    }

    return (
        <>
            <h1>{t('popolnenie-balansa')}</h1>
            <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="balance">
                        <div className="balance_pay">{t('vybirete-sposob-oplaty')}</div>
                        <select>
                            <option>{t('usdt-trc-20-0')}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-10">
                    <div className="balance">
                        <div className="balance_pay">{t('nomer-koshelka')}</div>
                        <div className="">
                            TS4ZqMj3jjHvcf7UwP6BaMdqvMNpnTJTFJ
                            <button onClick={() => navigator.clipboard.writeText('TS4ZqMj3jjHvcf7UwP6BaMdqvMNpnTJTFJ')}>
                                <img src="/images/cop.png" alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Row className="mt-4">
                <Col xs={12} lg={4} >
                    <button onClick={handleModalOpen} className="btn-green lc_body_btn">{t('ya-oplatil')}</button>
                </Col>
            </Row>
            <div className="row mt-5">
                <div className="col">
                    <div className="balance_inf">
                        <p>
                            {t('posle-osushestvleniya-tranzakcii-pozhaluista-predostavte-nam-skrinshot-v-telegramme-kak-tolko-sredstva-postupyat-nam-na-balans-oni-takzhe-budut-zachislenny-i-na-vash-balans-v-lichnom-kabinete')}
                        </p>
                        <a className="balance_tg" href="https://t.me/LokiYour_bot">
                            <img src="/images/tg.png" alt="tg" />
                            {t('nash-telegram')}
                        </a>
                    </div>
                </div>
                <Modal show={modalShow} onHide={handleModalClose} centered>
                    <Modal.Body className="lc_body">
                        <Row>
                            <Col xs={12} className="mb-2" >
                                <input value={amount || ''} onChange={(e) => Number(e.target.value) > 0 && setAmount(Number(e.target.value))} type="number" placeholder={t('summa')} />
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Dropzone onDrop={acceptedFiles => setFile(acceptedFiles[0])}>
                                    {({ getRootProps, getInputProps }) => (

                                        <div {...getRootProps()} className='input'>
                                            <input {...getInputProps()} />
                                            <span>{file ? file.name : t('skrinshot')}</span>
                                        </div>

                                    )}
                                </Dropzone>
                            </Col>
                            <Col xs={12} >
                                <button onClick={send} style={{ width: '100%' }} className="btn-green lc_body_btn">{t('otpravit')}</button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Replenishment