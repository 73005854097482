import { Paginated, Query } from "@feathersjs/feathers"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { createUsers } from "../helpers/fake"
import client from "../helpers/feathers"
import { IPurchase, ITransaction, ITransactionIncome, ITransactionPurchase, ITransactionReward, IUser } from "../interfaces/services"
import { IStructure, StructureDispatch, StructureType } from "../interfaces/structure"
import useUserContext from "./useUserContext"


const getUsers = (init: IUser[]): Promise<Paginated<IUser>> => {
    const limit = 900
    const select = ['_id', 'referrer','balance']
    const query = {
        referrer: {
            $in: init.map(u => u._id)
        },
        $limit: limit,
        $select: select
    }
    return client.
        service('users').
        find({ query })
}

const getRewards = (user: IUser, level?: number): Promise<Paginated<ITransactionReward>> => {
    const limit = 900
    const select = ['_id', 'amount', 'count', 'level']
    const query: Query = {
        user: user._id,
        transactionType: 'reward',
        status: 'approved',
        $limit: limit,
        $select: select
    }
    if (level) {
        query['level'] = level
    }
    return client.
        service('transactions').
        find({ query })
}

const getTransactionIncomes = (user: IUser, level?: number): Promise<Paginated<ITransactionIncome>> => {
    const limit = 900
    const select = ['_id', 'amount', 'level']
    const query: Query = {
        user: user._id,
        transactionType: 'income',
        status: 'approved',
        $limit: limit,
        $select: select
    }
    if (level) {
        query['level'] = level
    }
    return client.
        service('transactions').
        find({ query })
}

const loader = async ({ user, dispatch }: { user: IUser; dispatch: StructureDispatch<IUser> }) => {
    const arr: StructureType<IUser> = []
    let init = [user]
    for (let i = 0; i < 5; i++) {
        const res = await getUsers(init)
        init = res.data
        arr.push(res)
    }
    dispatch(arr)
}

const useStructure = (user?: IUser): IStructure => {
    const authUser = useUserContext()
    if (!user)
        user = authUser

    const [loaded, setLoaded] = useState(false)
    const [structure, setStructure] = useState<StructureType<IUser>>([])

    useEffect(() => {

        if (user && !loaded)
            loader({ user, dispatch: setStructure })
                .then(() => setLoaded(true))
    }, [user])

    const getLevel = (level: number) => {
        return structure[level - 1]
    }

    const getTotal = () => {
        return structure.reduce((total, level) => total + level.total, 0)
    }

    const getTransactions = (level?: number) => {
        if (user && loaded)
            return getRewards(user, level)
        else
            return Promise.reject()
    }

    const getSum = (transactions: Paginated<ITransaction>) => transactions.data.reduce((total, transaction) => total + transaction.amount, 0)

    const getIncomes = (level?: number) => {
        if (user && loaded)
            return getTransactionIncomes(user, level)
        else
            return Promise.reject()
    }

    return { structure, loaded, getLevel, getTotal, getTransactions, getSum, getIncomes }
}

export default useStructure