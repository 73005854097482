import { Paginated } from "@feathersjs/feathers"
import { useEffect, useState } from "react"
import { Accordion } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import client from "../../helpers/feathers"
import { IService } from "../../interfaces/services"

interface IFaq extends IService {
    _question: any,
    _answer: any,
}

const previousData: IFaq[] = [
]

const Faqs = () => {
    const [faqs, setFaqs] = useState<IFaq[]>(previousData)
    const { t, i18n } = useTranslation();

    useEffect(() => {
        client.service('faqs').find()
            .then((res: Paginated<IFaq>) => {
                if (res.total > 0)
                    setFaqs(res.data)
            })
    }, [])

    return (
        <>
            <Accordion>
                {faqs.map(faq => (
                    <Accordion.Item key={faq._id} eventKey={faq._id} >
                        <Accordion.Header>{faq._question[i18n.language]||''}</Accordion.Header>
                        <Accordion.Body><div dangerouslySetInnerHTML={{ __html: faq._answer[i18n.language]|| '' }} /></Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    )
}

export default Faqs