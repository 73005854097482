import { Paginated } from "@feathersjs/feathers"
import { useEffect, useState } from "react"
import { Col, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import client from "../../helpers/feathers"
import { IUser, IPurchase } from "../../interfaces/services"

interface StructureBlockProps {
    level: number,
    users: IUser[],
}

const StructureBlock = (props: StructureBlockProps) => {
    const { level, users } = props
    const { t } = useTranslation()
    const [all, setAll] = useState<IUser[]>([])
    const [referrals, setReferrals] = useState<Paginated<IUser>>()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        client.service('users').find({ query: { referrer: { $in: users.map(u => u._id) }, $limit: 200, $select: ['_id', 'referrer'] } })
            .then((r: Paginated<IUser>) => setAll(r.data))
        client.service('users').find({ query: { referrer: { $in: users.map(u => u._id) }, $limit: 6 } })
            .then((r: Paginated<IUser>) => setReferrals(r))
    }, [users])

    const loadMore = () => {
        if (!loaded && referrals) {
            setLoaded(true)
            client.service('users').find({ query: { referrer: { $in: users.map(u => u._id) }, $limit: 6, $skip: referrals.data.length } })
                .then((r: Paginated<IUser>) => {
                    r.data = [...referrals.data, ...r.data]
                    setReferrals(r)
                    setLoaded(false)
                })
        }
    }

    return (
        <>
            <div className="lc_lvl">
                <div className="lc_lvl_numbe">{level} {t('uroven')}</div>
                <div className="lc_lvl_value">{referrals && referrals.total} {t('referalov')}</div>
            </div>
            <Row className=" name g-4">
                {referrals &&
                    referrals.data.map(referral => (
                        <StructureItem referral={referral} key={referral._id} />
                    ))
                }
                {(!loaded && referrals && referrals.total > referrals.data.length) &&
                    <Col xs={12} lg={'auto'}>
                        <button onClick={loadMore}>{t('pokazat-vse')}</button>
                    </Col>
                }
            </Row>

            {level < 5 &&
                <StructureBlock users={all} level={level + 1} />
            }
        </>
    )
}

const StructureItem = ({ referral }: { referral: IUser }) => {
    const [show, setShow] = useState(false);
    const [purchases, setPurchases] = useState<Paginated<IPurchase>>();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        client
            .service('purchases')
            .find({
                query: {
                    user: referral?._id,
                }
            })
            .then((r: Paginated<IPurchase>) => setPurchases(r))
    }, [referral])

    return (
        <>
            <Col
                xs={6}
                lg={4}
                className={referral.status ? 'referal_active' : ''}
                onClick={handleShow}
            >
                {referral.firstName} {referral.lastName} <span>{referral.rank}</span>
            </Col>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Счета</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {purchases &&
                        purchases.data.map(item => (
                            <p>{item.tradingAccount}</p>
                        ))
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
export default StructureBlock