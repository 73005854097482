import { DashboardMenuItem, Menu, MenuItemLink, MenuProps, ResourceDefinition, useBasename, useResourceDefinitions } from "react-admin";
import LabelIcon from '@mui/icons-material/Label';
import { PropsWithChildren, useEffect, useState } from "react";
import { ListItem, ListItemIcon, ListItemText, Collapse, List } from '@mui/material';
import { Inbox as InboxIcon, ExpandLess, ExpandMore, ViewList, Home,Help } from '@mui/icons-material'

interface MenuItemProps {
    resource: ResourceDefinition,
    sub?: Isub
}

interface SubMenuProps {
    sub:Isub
}

interface Isub {
    name: string,
    label: string,
    Icon: any
}

const subs: Isub[] = [
    {
        name: 'home',
        label: 'Главная',
        Icon: Home
    },
    {
        name:'support',
        label: 'Поддержка',
        Icon: Help
    }
]
export const AdminMenu = (props: MenuProps) => {
    const resources = useResourceDefinitions()

    return (
        <Menu {...props}>
            <DashboardMenuItem />
            {subs.map(sub => (
                <SubMenu sub={sub} key={sub.name}>
                    {Object.keys(resources).map(name => (
                        <MenuItem resource={resources[name]} sub={sub} key={name} />
                    ))}
                </SubMenu>
            ))}
            {Object.keys(resources).map(name => (
                <MenuItem resource={resources[name]} key={name}/>
            ))}
            {/* <SubMenu /> */} 
            <MenuItemLink to="/admin/settings" primaryText="settings" leftIcon={<LabelIcon />} />
        </Menu>
    )
};

const MenuItem = ({ resource, sub }: MenuItemProps) => {
    const baseName = useBasename()
    if ((resource.options && sub && sub.name === resource.options.sub) || (!sub && (!resource.options || !resource.options.sub)))
        return (
            <MenuItemLink
                key={resource.name}
                to={`${baseName}/${resource.name}`}
                primaryText={
                    (resource.options && resource.options.label) ||
                    resource.name
                }
                leftIcon={
                    resource.icon ? <resource.icon/> : <ViewList />
                }
            // onClick={props.onMenuClick}
            // sidebarIsOpen={open}
            />
        )
    else
        return null
}

const SubMenu = (props: PropsWithChildren<SubMenuProps>) => {
    const [open, setOpen] = useState(false)
    const resources = useResourceDefinitions()

    const handleClick = () => setOpen(!open)

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    <props.sub.Icon />
                </ListItemIcon>
                <ListItemText primary={props.sub.label} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {props.children}
                </List>
            </Collapse>
        </>
    )
}