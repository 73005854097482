import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import client from "../../helpers/feathers"
import useStructure from "../../hooks/useStructure"
import useStructureContext from "../../hooks/useStructureContext"
import { IStructure } from "../../interfaces/structure"

const percents = [33, 15, 10, 5, 3]

const Sales = () => {
    const { t } = useTranslation()
    // const _structure = useStructure()
    const { structure, loaded, getTotal, getTransactions, getSum } = useStructureContext()
    const [total, setTotal] = useState(0)
    const [sum, setSum] = useState(0)

    useEffect(() => {
        if (loaded)
            getTransactions()
                .then((transactions) => {
                    setSum(getSum(transactions))
                    setTotal(transactions.data.reduce((sum, transaction)=> sum + (transaction.count || 0), 0))
                })
    }, [loaded])

    return (
        <>
            <div className="affiliate_filter">
                <div className="affiliate_filter_label">{t('statistika')}</div>
                <select name="time" className="affiliate_filter_select">
                    <option value={t('za-poslednii-mesyac')}>{t('za-vse-vremya')}</option>
                </select>
            </div>
            <h3 className="affiliate_h3">{t('vsego')}</h3>
            {loaded &&
                <>
                    <div className="affiliate_dashboard">
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <div className="affiliate_col affiliate_col-lg">
                                    <div className="affiliate_col_label">{t('zaregistrirovano')}</div>
                                    <div className="affiliate_col_value">{getTotal()}</div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="affiliate_col affiliate_col-lg">
                                    <div className="affiliate_col_label">{t('prodano-loki')}</div>
                                    <div className="affiliate_col_value">{total}</div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="affiliate_col affiliate_col-lg">
                                    <div className="affiliate_col_label">{t('zarabotano')}</div>
                                    <div className="affiliate_col_value affiliate_col_value-green">{sum} $</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="affiliate_h3">{t('referalnye-urovni-prodazh')}</h3>
                    {structure.map((level, index) => (
                        <Level level={index + 1} key={index} />
                    ))}
                </>
            }
        </>
    )
}

interface LevelProps {
    level: number,
    // structure: IStructure
}

const Level = ({ level }: LevelProps) => {
    const { t } = useTranslation()
    const { getLevel, getTransactions, getSum } = useStructureContext()
    const [total, setTotal] = useState(0)
    const [sum, setSum] = useState(0)

    useEffect(() => {
        getTransactions(level)
            .then((transactions) => {
                setTotal(transactions.data.reduce((sum, transaction)=> sum + (transaction.count || 0), 0))
                setSum(getSum(transactions))
            })
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-6 col-lg-8 affiliate_lvl_label"><span>{level}</span> {t('uroven')}</div>
                <div className="col-6 col-lg-4 affiliate_lvl_value">{percents[level - 1]}{t('za-prodazhu')}</div>
            </div>
            <div className="row affiliate_pan">
                <div className="col-12 col-lg-4">
                    <div className="affiliate_col affiliate_col">
                        <div className="affiliate_col_label">{t('zaregistrirovano-0')}</div>
                        <div className="affiliate_col_value">{getLevel(level).total}</div>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="affiliate_col affiliate_col">
                        <div className="affiliate_col_label">{t('prodano-loki-0')}</div>
                        <div className="affiliate_col_value">{total}</div>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="affiliate_col affiliate_col">
                        <div className="affiliate_col_label">{t('zarabotano-0')}</div>
                        <div className="affiliate_col_value affiliate_col_value-green">{sum} $</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sales