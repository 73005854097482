import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useStructureContext from "../../hooks/useStructureContext"

interface LevelProps {
    level: number,
}

const percents = [9, 4, 2, 2, 1]
const percentsHendz = [6, 3, 2, 1, 1]

const Turnover = () => {
    const { t } = useTranslation()
    const { structure, loaded, getIncomes, getSum } = useStructureContext()
    const [total, setTotal] = useState(0)
    const [lots, setLots] = useState(0)

    useEffect(() => {
        if (loaded)
            getIncomes()
                .then((incomes) => {
                    setTotal(getSum(incomes))
                    setLots(incomes.data.reduce((total, haystack) => {
                        console.log(haystack);
                        return total + (haystack.amount / percents[haystack.level - 1])
                    }, 0))
                })
    }, [loaded])

    return (
        <>
            <div className="affiliate_filter">
                <div className="affiliate_filter_label">{t('statistika')}</div>
                <select name="time" className="affiliate_filter_select">
                    <option value={t('za-poslednii-mesyac')}>{t('za-poslednii-mesyac')}</option>
                </select>
            </div>
            {loaded &&
                <>
                    <h3 className="affiliate_h3">{t('vsego')}</h3>
                    <div className="affiliate_dashboard">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6">
                                <div className="affiliate_col affiliate_col-lg">
                                    <div className="affiliate_col_label">{t('torgovyi-oborot')}</div>
                                    <div className="affiliate_col_value">{lots.toFixed(2)} <span className="small">{t('lota')}</span></div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="affiliate_col affiliate_col-lg">
                                    <div className="affiliate_col_label">{t('partnerskoe-voznagrazhdenie')}</div>
                                    <div className="affiliate_col_value affiliate_col_value-green">{total.toLocaleString()} $</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="affiliate_h3">{t('nachisleniya-oborota-s-urovnei')}</h3>
                    {structure.map((_level, index) => (
                        <Level level={index + 1} key={index} />
                    ))}
                </>
            }
        </>
    )
}

const Level = ({ level }: LevelProps) => {
    const { t } = useTranslation()
    const { getIncomes, getSum } = useStructureContext()
    const [total, setTotal] = useState(0)
    const [lots, setLots] = useState(0)

    useEffect(() => {
        getIncomes(level)
            .then((incomes) => {
                const _sum = getSum(incomes)
                setTotal(_sum)
                setLots(_sum / percents[level - 1])
            })
    }, [])

    return (
        <>
            <div className="row align-items-center">
                <div className="col affiliate_lvl_label"><span>{level}</span> {t('uroven')}</div>
                <div className="col-auto affiliate_lvl_value">{percents[level - 1]}({percentsHendz[level - 1]} Hedz){t('za-lot')}</div>
            </div>
            <div className="row align-items-center affiliate_pan">
                <div className="col-12 col-lg-6">
                    <div className="affiliate_col affiliate_col">
                        <div className="affiliate_col_label">{t('torgovyi-oborot')}</div>
                        <div className="affiliate_col_value">{lots.toFixed(2)} <span className="small">{t('lota-0')}</span></div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="affiliate_col affiliate_col">
                        <div className="affiliate_col_label">{t('partnerskoe-voznagrazhdenie-0')}</div>
                        <div className="affiliate_col_value affiliate_col_value-green">{total.toLocaleString()} $</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Turnover