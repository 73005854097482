import { PropsWithChildren, useState } from "react"
import { Toast, ToastContainer } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import notificationContext from "../contexts/notificationContext"
import { INotification } from "../interfaces/notifications"

const Provider = notificationContext.Provider

const NotificationWrapper = ({ children }: PropsWithChildren<{}>) => {
    const { t } = useTranslation()
    const [list, setList] = useState<INotification[]>([])

    const addNotification = (notification: INotification, time?: number) => {
        const array = list.slice()
        array.push(notification)
        setList(array)
        if (time)
            setTimeout(() => removeNotification(notification.id), time)
    }

    const removeNotification = (notificationId: string) => {
        setList(list.filter(item => item.id !== notificationId))
    }

    return (
        <Provider value={{ list, addNotification, removeNotification }}>
            {children}
            <ToastContainer position="top-end" className="p-3 position-fixed" style={{zIndex:1060}}>
                {list.map(item => (
                    <Toast bg="dark" key={item.id}>
                        <div className="d-flex">
                            <Toast.Body style={{ color: 'white' }}>{item.text}</Toast.Body>
                            <button
                                type="button"
                                className="btn-close btn-close-white me-2 m-auto"
                                aria-label={t('close')}
                                onClick={() => removeNotification(item.id)}
                            />
                        </div>
                    </Toast>
                ))}
            </ToastContainer>
        </Provider>
    )
}

export default NotificationWrapper