import { Admin as RAdmin, AuthProvider, CustomRoutes, DashboardMenuItem, fetchUtils, Layout, LayoutProps, LoginComponent, Resource, usePermissions } from "react-admin"
import { restClient, authClient } from 'ra-data-feathers';
import client from "../helpers/feathers";
import { ThemeOptions } from '@mui/material'
import { useNavigate } from "react-router-dom";
import { AdminMenu } from "../admin/AdminMenu";
import getAuthProvider from "../helpers/authProvider";
import getResources from "../helpers/getResources";
import LoginForm from "../components/LoginForm";
import { Modal } from "react-bootstrap";
import Dashboard from "../admin/Dashboard";
import { useEffect } from "react";

const restClientOptions = {
    id: '_id',
    usePatch: true,
    customQueryOperators: ['$text', '$search', '$regex']
};
const authClientOptions = {
    // authenticate: {
    //     strategy: 'local',
    // },
    // permissionKey:'permissions',
    // permissionField: 'permissions',
    // passwordField?: string;
    // usernameField?: string;
    // redirectTo:'/login'
    // logoutOnForbidden?: boolean;
}

const theme: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#2dbe85',
        },
        secondary: {
            main: '#ee3a55',
        },
        background: {
            default: '#0f111a'
        }
    }
}

const MyLayout = (props: LayoutProps) => <Layout {...props} menu={AdminMenu} />;

const MyLoginPage: LoginComponent = (props: any) => {
    const navigate = useNavigate()
    return (
        <Modal show={true} onHide={() => navigate('/')} centered>
            <Modal.Body>
                <LoginForm redirect={'/admin'} />
            </Modal.Body>
        </Modal>
    )
}

const Admin = (props: any) => {

    return (
        <RAdmin
            basename="/admin"
            dataProvider={restClient(client, restClientOptions)}
            authProvider={getAuthProvider(client, authClientOptions)}
            theme={theme}
            layout={MyLayout}
            requireAuth
            loginPage={MyLoginPage}
            dashboard={Dashboard}
        >
            {/* <Resource {...UserProps} /> */}
            {permissions => getResources(permissions)}
            {/* {permissions => [
                <Resource {...UserProps} />,
                permissions === 'admin' ? // Only show this resource if the user role is 'admin'
                    <Resource {...SupportProps} /> :
                    <Resource {...MessageProps} />
            ]} */}

        </RAdmin>
    )
}

export default Admin