import { RichTextInput } from "ra-input-rich-text"
import { List, Datagrid, TextField, EditButton, Create, SimpleForm, TextInput, ImageInput, ImageField, Edit, ResourceProps, NumberInput, NumberField } from "react-admin"
import { uploadTransform } from "../helpers/admin"
import InventoryIcon from '@mui/icons-material/Inventory';

const RESOURCE: string = 'bundles'

const BundleList = () => {
    return (
        <List
            resource={RESOURCE}
        >
            <Datagrid>
                <TextField source="title" />
                <NumberField source="price" />
                <NumberField source="sale" />
                <EditButton />
            </Datagrid>
        </List>
    )
}

const BundleCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <NumberInput source="count" fullWidth />
                <TextInput source="title" fullWidth />
                <NumberInput source="price" fullWidth />
                <NumberInput source="sale" fullWidth />
            </SimpleForm>
        </Create>
    )
}

const BundleEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <NumberInput source="count" fullWidth />
                <TextInput source="title" fullWidth />
                <NumberInput source="price" fullWidth />
                <NumberInput source="sale" fullWidth />
            </SimpleForm>
        </Edit>
    )
}

export const BundlesProps: ResourceProps = {
    name: RESOURCE,
    icon:InventoryIcon,
    list: BundleList,
    edit: BundleEdit,
    create: BundleCreate,
    options: {
        label: 'Наборы'
    }
}