import { TransformData } from "react-admin";
import { API } from "../constants";
import { uploadFile } from "./uploads";

export const uploadTransform = (field: string): TransformData => {
    return async (data: any, options) => {
        const image = data[field]
        if (!image || (options?.previousData[field] && options?.previousData[field].src === image.src))
            return data

        data[field] = await uploadFile(image.rawFile);

        return data;
    };
}