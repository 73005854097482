import { RichTextInput } from "ra-input-rich-text"
import { Create, Datagrid, DateField, Edit, EditButton, FunctionField, List, ReferenceField, ReferenceInput, ReferenceManyField, ResourceProps, RichTextField, SelectInput, Show, ShowButton, SimpleForm, Tab, TabbedShowLayout, TextField, TextInput } from "react-admin"
import MessageIcon from '@mui/icons-material/Message';

const RESOURCE: string = 'messages'

export const MessageList = () => {
    return (
        <List resource={RESOURCE}>
            <Datagrid>
                <ReferenceField link="show" source='supports' reference='support'>
                    <TextField source='theme' />
                </ReferenceField>
                <ReferenceField link="show" source='user' reference='users'>
                    <FunctionField
                        label="Имя"
                        render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                    />
                </ReferenceField>
                <TextField source='text' />
                <DateField source='createdAt' showTime />
                {/* <Date source='createdAt' /> */}
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    )
}

export const MessageShow = () => {
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField source='id' />
                    <ReferenceField link="show" source='supports' reference='support'>
                        <TextField source='theme' />
                    </ReferenceField>
                    <ReferenceField link="show" source='user' reference='users'>
                        <FunctionField
                            label="Имя"
                            render={(record: any) => (record.firstName && record.lastName) ? `${record.firstName} ${record.lastName}` : record.email}
                        />
                    </ReferenceField>
                    <TextField source='text' />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export const MessageEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <TextInput disabled source="id" />
                <ReferenceInput source='support' reference='supports'>
                    <SelectInput optionText="theme" disabled />
                </ReferenceInput>
                <ReferenceInput source='user' reference='users'>
                    <SelectInput optionText="email" disabled />
                </ReferenceInput>
                <TextInput multiline source='text' />
            </SimpleForm>
        </Edit>
    )
}

export const MessageCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <ReferenceInput source='support' reference='supports'>
                    <SelectInput optionText="theme" />
                </ReferenceInput>
                <ReferenceInput source='user' reference='users'>
                    <SelectInput optionText="email" />
                </ReferenceInput>
                <TextInput multiline source='text' />
            </SimpleForm>
        </Create>
    )
}


export const MessageProps: ResourceProps = {
    name: RESOURCE,
    icon: MessageIcon,
    list: MessageList,
    edit: MessageEdit,
    show: MessageShow,
    create: MessageCreate,
    options: {
        label: 'Сообщения',
        sub: 'support'
    }
}