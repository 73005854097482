import { Create, Datagrid, Edit, EditButton, ImageField, ImageInput, List, ResourceProps, SimpleForm, TextField, TextInput, TransformData, TranslatableInputs } from 'react-admin'
import { RichTextInput } from 'ra-input-rich-text';
import { API } from '../constants';
import { uploadTransform } from '../helpers/admin';
import { useTranslation } from 'react-i18next';

const RESOURCE: string = 'impacts'

const ImpactList = () => {
    return (
        <List
            resource={RESOURCE}

        >
            <Datagrid>
                <TextField source="title" />
                <EditButton />
            </Datagrid>
        </List>
    )
}

const ImpactCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <TextInput source="title" fullWidth />
                <RichTextInput source="text" fullWidth />
            </SimpleForm>
        </Create>
    )
}

const ImpactEdit = () => {
    const { i18n } = useTranslation()

    return (
        <Edit>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" fullWidth />
                <TranslatableInputs locales={i18n.languages as string[]} >
                    <TextInput source="_title" fullWidth />
                    <RichTextInput source="_text" fullWidth />
                </TranslatableInputs>
                <TextInput source="title" fullWidth />
                <RichTextInput source="text" fullWidth />
            </SimpleForm>
        </Edit>
    )
}

export const ImpactsProps: ResourceProps = {
    name: RESOURCE,
    list: ImpactList,
    edit: ImpactEdit,
    create: ImpactCreate,
    options: {
        label: 'Что получает клиент',
        sub: 'home'
    }
}